import AuthService from '@/services/auth-service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true, loggedAt: null }, user, location: null}
  : { status: { loggedIn: false, loggedAt: null }, user: null, location: null};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user)
        .then(
            user => {
              commit('auth/loginSuccess', user, {root: true});
              return Promise.resolve(user);
            },
            error => {
              commit('loginFailure');
              return Promise.reject(error);
            }
          )
        .then(user => {
            commit('user/getProfile', user.user_data, {root: true});

            return Promise.resolve(user);
        });
    },
    refreshToken({ commit }) {
      return AuthService.refresh(user.refresh_token).then(
        data => {
          commit('refreshToken', data);
          return Promise.resolve(data);
        }
      );
    },
    logout({ commit }) {
        AuthService.logout();
        commit('logout');
    },
    register({ commit }, user) {
        return AuthService.register(user).then(
            response => {
                commit('registerSuccess');
                return Promise.resolve(response.data);
            },
            error => {
                commit('registerFailure');
                return Promise.reject(error);
            }
        );
    },
    getUserLocation({ commit }) {
        navigator.geolocation.getCurrentPosition(function(position) {
            commit('setUserLocation', position);
        });
    }
  },
  mutations: {
    loginSuccess(state, user) {
        state.status.loggedIn = true;
        state.status.loggedAt = Date.now();
        state.user = user;
    },
    refreshToken(state, data) {
        state.status.loggedIn = true;
        state.user.token = data.token;
        state.user.refresh_token = data.refresh_token;
        state.user.token_type = data.token_type;
        state.user.expires_in = data.expires_in;
    },
    loginFailure(state) {
        state.status.loggedIn = false;
        state.user = null;
    },
    logout(state) {
        state.status.loggedIn = false;
        state.user = null;
    },
    registerSuccess(state) {
        state.status.loggedIn = false;
    },
    registerFailure(state) {
        state.status.loggedIn = false;
    },
    setUserLocation(state, position) {
        state.location = position;
    }
  },
  getters: {
    getUserToken(state) {
        if(state.user) {
          return state.user.token ?? null;
        }
    },
    isLogged(state) {
      return state.status.loggedIn;
    },
    userType(state) {
      return (state.user) ? state.user.user_data.type : 0;
    }
  }
};