<template>
  <form
    enctype="multipart/form-data"
    method="post"
    class="cart ofertform"
    action="#"
  >
    <div class="row" v-if="poolState == 2">
      <div class="col-12">
        <h4 class="cazul text-7">
          <i class="fas fa-check-circle mr-2"
            v-bind:class="{'text-success':pool.last_price}"
          ></i>
          Pool finalizado
        </h4>
        <p v-if="!pool.last_price">
          Lamentablemente no se ha podido conseguir una oferta para este pool.
        </p>
      </div>
    </div>

    <div class="row" v-else-if="pool.volume >= criticMass">
      <div class="col-12">
        <h4 class="cazul text-7">
          <i class="fas fa-check-circle mr-2 text-success"></i>
          Pool completo
        </h4>
      </div>
      <div class="col-12">
        <p>Estamos buscando el mejor precio para vos.</p>
      </div>
    </div>

    <div class="row" v-else>
      <div v-if="reservations.count == 0" class="col-12">
        <p class="ofertaxtarget">
          <b>Selecciona la cantidad</b>
          <br />Seleccioná los {{ unit }} que necesitas
        </p>
        <input type="text" class="js-input" value="0" disabled />
      </div>

      <div v-else class="col-md-12">
        <div class="alert alert-warning" role="alert">
            <p class="ofertaxtarget text-success">
                <b>Ya tenés una reserva por {{ reservations.total_amount }}
              {{ $helpers.formatUnit(unit) }}</b>
            </p>
        </div>
      </div>

      <div class="col-sm-9 col-7">
        <input
          type="text"
          class="js-range-slider"
          id="rangopool"
          v-model="new_reservation.amount"
        />
      </div>
      <div class="col-sm-3 col-5 text-right">
        <a
          v-if="loggedIn && !had_reservation"
          class="btn btn-naranja btn-modern text-uppercase"
          v-on:click="makeReservation()"
          >UNITE AL POOL</a>
        <a
          v-else-if="loggedIn"
          class="btn btn-naranja btn-modern text-uppercase"
          v-on:click="makeReservation()"
          >AGREGAR RESERVA</a>
        <a
          v-else
          class="btn btn-naranja btn-modern text-uppercase"
          v-on:click="showLoginModal = true"
          >UNITE AL POOL</a>
      </div>
      <app-login-modal
        :show="showLoginModal"
        v-on:close="showLoginModal = false"
      />
      <app-pool-reservation-modal
        v-if="loggedIn"
        :show="showReservationModal"
        v-bind:reservation="reservationData()"
        v-on:close="updateReservation()"
      />
    </div>
    
  </form>
</template>
<script>
import { mapState } from "vuex";
import AppLoginModal from "@/components/layout/common/AppLoginModal";
import AppPoolReservationModal from "@/components/layout/pool/AppPoolReservationModal";
import $ from "jquery";
import ionRangeSlider from "ion-rangeslider"; // eslint-disable-line no-unused-vars

export default {
  components: { AppLoginModal, AppPoolReservationModal },
  name: "AppPoolReservation",
  data: () => {
    return {
        had_reservation: false,
        range_slider: null,
        new_reservation: { amount: 0 },
            showLoginModal: false,
            showReservationModal: false,
            poolVolume: 0,
        };
  },
  methods: {
    reservationData() {
      this.new_reservation.pool = this.pool;
      return this.new_reservation;
    },
    makeReservation() {
      if (this.new_reservation.amount == 0) {
        return false;
      }
      this.showReservationModal = true;
    },
    updateReservation() {
        this.showReservationModal = false;
        this.$emit('resetprogress');
        this.range_slider.update({
            max: (this.criticMass - this.volume),
            from: 0
        });

        // Check if buyers update is necessary
        if(!this.had_reservation) {
            this.$store.commit('pool/addBuyer');
            this.had_reservation = true;
        }
    },
    loadRangeSlider() {
        var $range = $("#rangopool"), $input = $(".js-input");
        const tmpUnit = this.$helpers.formatUnit(this.unit);
    
        let self = this;
        $range.ionRangeSlider({
            skin: "round",
            type: "single",
            grid: true,
            hide_min_max: true,
            min: 0,
            max: (this.criticMass - this.volume),
            step: parseInt(this.pool.product.unidad_minima) ?? 5,
            from: 0,
            from_min: 0,
            from_fixed: false,
            onStart: function (data) {
                $input.prop("value", data.from + tmpUnit);
            },
            onChange: function (data) {
                $input.prop("value", data.from + tmpUnit);
            },
            onFinish: function (data) {
                const amount = data.from;
                const newProgress = (amount / self.criticMass) * 100;
                self.$emit('changeprogress', newProgress);
                self.$emit('changevolume', amount);
                self.new_reservation.amount = amount;
            },
        });

        this.range_slider = $range.data('ionRangeSlider');
    },
  },
  computed: mapState({
    pool: (state) => state.pool.data,
    unit: (state) => state.pool.data.product.unidad_medida,
    loggedIn: (state) => state.auth.status.loggedIn,
    criticMass: (state) => state.pool.data.product.masa_critica_1,
    volume: (state) => state.pool.data.volume,
    reservations: (state) => state.pool.reservations,
    totalReservation: (state) => state.pool.reservations ? state.pool.reservations.total_amount : 0,
    poolState: (state) => state.pool.data.state,
  }),
  mounted() {
    this.loadRangeSlider();
    if(this.totalReservation) {
        this.had_reservation = true;
    }
  },
};
</script>