<template>
    <div>
        <GmapMap
            ref="mapRef"
            :center="center"
            :zoom="7"
            map-type-id="terrain"
            style="width: 100%; height: 350px;"
            :options="{disableDefaultUI: true}"
        >
            <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :draggable="false"
                @click="center=m.position"
            />
        </GmapMap>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    
    export default {
        name: 'AppPoolMap',
        data: () => {
            return {
                markers: [],
                bounds: null,
                center: {}
            }
        },
        computed: mapState({
            newBid: (state) => state.pool.new_bid,
            pool: (state) => state.pool.data,
            reservations: (state) => state.pool.reservations.shipping_list
        }),
        methods: {
            formatMarkers(reservations) {
                let self = this;

                for (const marker of reservations) {
                    self.markers.push({
                        position: {
                            lat: marker.lat,
                            lng: marker.lon
                        }
                    });
                }
            },
            setCenter(reservation) {
                this.center = {
                    lat: reservation.lat,
                    lng: reservation.lon
                };
            }
        },
        mounted() {
            this.setCenter(this.reservations[0]);
            this.formatMarkers(this.reservations);
        }
    }
</script>
