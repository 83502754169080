import UserService from '@/services/user-service';

export const user = {
    namespaced: true,
    state: {
        notifications: [],
        data: {},
        user_id: null,
        register_data: {
            step: 1,
            user_type: null
        }
    },
    actions: {
        getNotifications({ commit }) {
            return UserService.getNotifications().then(
                notifications => {
                  commit('getNotifications', notifications.data);
                  return Promise.resolve(notifications.data);
                }
            );
        },
        getProfile({ commit }) {
          return UserService.getProfile().then(
            user => {
              commit('getProfile', user.data);
              return Promise.resolve(user.data);
            }
          );
        },
        register({ commit, state }, data) {
            let formatted_data = {};
            formatted_data.type = state.register_data.user_type;
            formatted_data.name = data.first_name + ' ' + data.last_name;
            if(data.company_name != '') {
                formatted_data.company_name = data.company_name;
                formatted_data.cuit = data.cuit;
            }
            formatted_data.email = data.email;
            formatted_data.phone = data.phone;
            formatted_data.password = data.password;
            formatted_data.password_confirmation = data.confirm_password;

            // addresses
            formatted_data.address_comments = data.address_comments;
            formatted_data.address_placeid = data.address.place_id;
            if(data.appartment != '') {
                formatted_data.appartment = data.appartment;
            }

            if(data.differentShippingAddress) {
                formatted_data.shipping_address_comments = data.shipping_address_comments;
                formatted_data.shipping_address_placeid = data.shipping_address.place_id;
                if(data.shipping_appartment != '') {
                    formatted_data.shipping_appartment = data.shipping_appartment;
                }
            }else{
                formatted_data.shipping_address_placeid = data.address.place_id;
                if(data.appartment != '') {
                    formatted_data.shipping_appartment = data.appartment;
                }
            }

            return UserService.register(formatted_data)
                .then(
                    response => {
                      commit('register', formatted_data);
                      commit('setUserId', response.data.user_id);
                      return Promise.resolve(response.data);
                    },
                    error => {
                        return Promise.reject(error);
                    }
                );
        }
    },
    mutations: {
        register(state, data) {
            data.step = state.register_data.step;
            state.register_data = data;
        },
        setUserId(state, user_id) {
            state.user_id = user_id;
        },
        getProfile(state, data) {
            state.data = data;
        },
        getNotifications(state, data) {
            state.notifications = data;
        },
        goToSecondRegistrationStep(state, user_type = null) {
            state.register_data.step = 2;
            if (user_type) {
                state.register_data.user_type = user_type;
            }
        },
        registrationNextStep(state, user_type = null) {
            state.register_data.step++;
            if (user_type) {
                state.register_data.user_type = user_type;
            }
        },
        registrationPreviousStep(state) {
            state.register_data.step--;
        }
    },
    getters: {
        getProfile: state => {
            return state.data
        }
    }
};
