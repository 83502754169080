<template>
    <div role="main" class="main shop pt-4">
        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          title=""
          v-model="user_created"
          @hide="close()"
          hide-footer
        >
            <div class="col-12 text-center">
                <i class="fas fa-check-circle text-color-primary text-10 mt-3"></i>
                <h2 class="text-center text-success mb-1">Usuario creado con exito</h2>
                <p>Te hemos enviado un email para confirmar tu casilla de correo.</p>
            </div>
        </b-modal>
        <div class="container">
            <div class="row">
                <div class="col">
                    <ul class="breadcrumb bd-block text-4 mb-4">
                        <li>
                            <a href="#" class="text-color-default text-color-hover-primary text-decoration-none"><i class="fas fa-home"></i></a>
                        </li>
                        <li><a href="#" class="text-color-default text-color-hover-primary text-decoration-none">Registro</a></li>
                        <li>Selecciona los productos</li>
                    </ul>
                </div>
            </div>
            <div class="row mb-5 pb-3 mt-1" id="thome">
                <div class="col-12">
                    <h4 class="cazul text-4 mb-3"><i class="far fa-arrow-alt-circle-right mr-2"></i> Seleccione que productos le interesan</h4>
                </div>
                <div class="col-12">
                    <form autocomplete="off" role="form" v-on:submit.prevent="savePreferences()" novalidate>
                        <div class="row">
                            <div class="col-md-4 mb-4 appear-animation animated fadeInUpShorter appear-animation-visible" style="animation-delay: 100ms;" v-for="(category, index) in categories.categories" :key="index">
                                <div class="card card-background-image-hover border-0 bgtrama">
                                    <div class="card-body text-center p-3">
                                        <i class="fas fa-tractor text-color-primary text-10"></i>
                                        <h4 class="card-title mt-2 mb-2 text-5 font-weight-bold">{{category.name}}</h4>
                                        <!-- <p class="card-text text-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> -->
                                        <div class="btn btn-naranja btn-modern">
                                            <div class="custom-checkbox-1 checkbox-custom-transparent">
                                                <input :id="'cat' + category.id" type="checkbox" :name="'prodcat' + category.id" value="1" v-on:change="selectCategory(category.id)">
                                                <label :for="'cat' + category.id" class="text-color-light">Seleccionar producto</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <hr />
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="form-group col-lg-8">
                                <div class="text-danger" v-if="error_message != ''">{{ error_message }}</div>
                            </div>
                            <div class="form-group col-lg-4 text-right">
                                <!-- <a href="registro-datos.php" class="mb-1 mt-1 mr-1 btn btn-light"><i class="fas fa-arrow-left"></i> Volver</a> -->
                                <button type="submit" v-if="!loading" class="mb-1 mt-1 mr-1 btn btn-naranja">
                                    Finalizar <i class="fas fa-check ml-1"></i>
                                </button>
                                <button type="button" v-else class="mb-1 mt-1 mr-1 btn btn-naranja">
                                Espere un momento...<i class="fas fa-arrow-right ml-1"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import UserService from '@/services/user-service'
    
    export default {
        name: 'AppRegisterThirdStep',
        computed: mapState([
            'categories'
        ]),
        data() {
            return {
                loading: false,
                error_message: '',
                user_created: false,
                selected: []
            }
        },
        methods: {
            selectCategory(id) {
                const index = this.selected.indexOf(id);
                if(index > -1) {
                    this.selected.splice(index, 1);
                }else{
                    this.selected.push(id);
                }
            },
            close() {
                this.$router.push({ name: 'Home' });
            },
            savePreferences() {
                this.error_message = '';
                if(this.selected.length == 0) {
                    this.error_message = 'Por favor seleccione alguna categoría';
                    return false;
                }

                UserService.savePreferences({
                    user_id: this.$store.state.user.user_id,
                    categories: this.selected
                }).then(() => {
                    this.user_created = true;
                }).catch(error => {
                    if(error.response.data.error_code == 'USER_PREFERENCES_REGISTRATION_EXPIRED') {
                        this.error_message = 'La sesión ha expirado';
                        return false;
                    }
                    this.error_message = 'Hubo un error al guardar sus preferencias';
                });
            }
        }
    }
</script>