import toast from '../toast'
import ErrorMessages from './error-messages';

function errorResponseHandler(error) {
    /* eslint-disable no-eval */

    if( error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false ) {
        return Promise.reject(error);
    }

    let showToast = true;
    if( error.config.hasOwnProperty('showError') && error.config.showError === false ) {
        showToast = false;
    }
    
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if(showToast) {
            const errorMessage = ErrorMessages.getMessage(error.response.data.error_code);
            toast.error(errorMessage);
        }
        throw error.response.data;
    } else if (error.request) {
        // NETWORK ERROR
        if(showToast) {
            toast.error(ErrorMessages.getMessage());
        }
    } else {
        if(showToast) {
            toast.error(ErrorMessages.getMessage());
        }
    }
}

export default errorResponseHandler;