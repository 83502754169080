<template>
  <div :class="colwidth">
    <div class="product mb-0 bg-color-white pt-3">
        <div class="product-thumb-info mb-3">
            <div class="product-thumb-info-badges-wrapper esqahorro">
                <span class="badge badge-ecommerce badget-ahorro">POTENCIAL AHORRO {{ discount() }}%</span>
            </div>
            <a v-on:click="goToPool">
                <div class="product-thumb-info-image">
                    <img alt="" class="img-fluid" :src="$helpers.apiAsset(product.imagen_destacada)" />
                </div>
            </a>
            <div class="bg-barra-cardpool"></div>
        </div>
        <div class="d-flex justify-content-between pl-3 pr-3 pb-4 pt-1">
            <div>
                <div class="pool-meta">
                    <ul class="breadcrumb bd-block text-4 mb-2 cnaranja">
                      <li>{{ product.category.name }}</li>
                      <li>{{ product.subcategory.name }}</li>
                    </ul>
                </div>
                <h3 class="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-1">
                    <a v-on:click="goToPool" class="text-color-dark text-color-hover-cyan">{{ product.nombre_comercial }}</a>
                </h3>
                <a v-on:click="goToPool" class="btn btn-azul btn-modern m-0 mt-2">VER PRODUCTO</a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import PoolService from '@/services/pool-service';

export default {
  name: 'AppPoolProduct',
  props: ['product', 'colwidth'],
  methods: {
    discount() {
        return Math.ceil(this.product.descuento_1);
    },
    price() {
        return this.$helpers.getPotentialPriceFromProduct(this.product);
    },
    goToPool() {
        // same logic as search
        const location = this.$store.state.auth.location;
        const location_data = (location) ? {lat: location.coords.latitude, lon: location.coords.longitude} : null;
        const token = this.$store.getters['auth/getUserToken'];

        PoolService.search(this.product.id, location_data, token)
        .then(data => {
          this.$router.push('/pool/' + data.pool_uuid);
        });
    }
  },
  mounted() {
  }
}
</script>