<template>
   <div role="main" class="main shop pt-4">
      <div class="container">
         <div class="row">
            <div class="col">
               <ul class="breadcrumb bd-block text-4 mb-4">
                  <li>
                     <a href="#" class="text-color-default text-color-hover-primary text-decoration-none"><i class="fas fa-home"></i></a>
                  </li>
                  <li><a href="#" class="text-color-default text-color-hover-primary text-decoration-none">Registro</a></li>
                  <li v-if="step==1">Tipo de usuario</li>
                  <li v-if="step==2">Datos personales</li>
                  <li v-if="step==3">Seleccionar productos</li>
               </ul>
            </div>
         </div>
         <div class="row">
            <div class="col">
               <div class="row process my-5">
                  <div class="process-step pasoneutro col-4 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
                     <div class="process-step-circle" v-bind:class="{active:(step>=1)}">
                        <strong class="process-step-circle-content">01</strong>
                     </div>
                     <div class="process-step-content">
                        <h4 class="text-3">Tipo de usuario</h4>
                     </div>
                  </div>
                  <div class="process-step pasopasivo col-4 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400">
                     <div class="process-step-circle" v-bind:class="{active:(step>=2)}">
                        <strong class="process-step-circle-content">02</strong>
                     </div>
                     <div class="process-step-content">
                        <h4 class="text-3">Datos personales</h4>
                     </div>
                  </div>
                  <div class="process-step pasopasivo col-4 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" >
                     <div class="process-step-circle" v-bind:class="{active:(step>=3)}">
                        <strong class="process-step-circle-content">03</strong>
                     </div>
                     <div class="process-step-content">
                        <h4 class="text-3">Productos</h4>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <AppRegisterFirstStep v-if="step===1" />
         <AppRegisterSecondStep v-if="step===2" />
         <AppRegisterThirdStep v-if="step===3" />
      </div>
   </div>
</template>
<script>
    // @ is an alias to /src
    import AppRegisterFirstStep from '@/components/layout/register/AppRegisterFirstStep.vue'
    import AppRegisterSecondStep from '@/components/layout/register/AppRegisterSecondStep.vue'
    import AppRegisterThirdStep from '@/components/layout/register/AppRegisterThirdStep.vue'

    import { mapState } from 'vuex';
   
    export default {
        name: 'Register',
        components: {
            AppRegisterFirstStep, AppRegisterSecondStep, AppRegisterThirdStep
        },
        computed: mapState({  
            step: state => state.user.register_data.step,
            loggedIn: state => state.auth.status.loggedIn
        }),
        mounted() {
            if(this.loggedIn) {
                this.$router.push({
                    name: 'Home' 
                });
            } 
        }
    }
</script>