<template>
  <form
    enctype="multipart/form-data"
    method="post"
    class="cart ofertform"
    action="#"
  >

    <!-- FINISHED POOL -->
    <div class="row" v-if="poolState == 2">
      <div class="col-12">
        <h4 class="cazul text-7">
          <i class="fas fa-check-circle mr-2"
            v-bind:class="{'text-success':pool.last_price}"
          ></i>
          Pool finalizado
        </h4>
        <p v-if="!pool.last_price">
          Lamentablemente no se ha podido conseguir una oferta para este pool.
        </p>
      </div>
    </div>

    <!-- FIRST STEP -->     
    <div class="row" v-if="poolState == 1">
      <div class="col-md-12" v-if="userBids.count > 0">
        <div class="alert alert-warning" role="alert">
            <p class="ofertaxtarget text-success">
              <i class="fas fa-money-check-alt text-success"></i>
              <b>Tu última oferta es de ${{ userBids.last_price }} 
                + ${{ userBids.last_shipping_price }} (distribución)</b>
            </p>
        </div>
      </div>

      <div class="col-12">
        <p class="ofertaxtarget">
          <b>Selecciona el precio por {{ $helpers.formatUnit(unit) }}</b>
        </p>
      </div>

      <div class="col-sm-9 col-7">
        <input
          type="text"
          class="js-range-slider"
          id="rangopool"
          v-model="bidPrice"
        />
      </div>
      <div class="col-sm-3 col-5 text-right">
        <a
          class="btn btn-naranja btn-modern text-uppercase" 
          v-on:click="nextStep"
          v-tooltip.auto="{
              show: (!$store.state.pool.data.last_price),
              content: 'Se el primero en ofertar', 
              trigger: 'manual',
              hideOnTargetClick: true
            }"
        >
          SIGUIENTE
        </a>
      </div>
    </div>

    <div class="row" v-else>
      <div class="col-md-8 ptarget border-right-0">
        <h4 class="mt-2">
          Activa las notificaciones para este pool y enterate cuando esté listo para ofertar.
        </h4> 
      </div>
      <div class="col-md-4">
        <a class="btn btn-naranja btn-modern text-uppercase mt-2" @click="subscribe">
            <i class="fas fa-bell mr-1"></i>
            <span v-if="!isFollowed">SUSCRIBIRME</span>
            <span v-else>ANULAR SUSCRIPCION</span>
        </a>
      </div>
    </div>
  </form>
</template>
<script>
import { mapState } from "vuex";
import $ from "jquery";
import ionRangeSlider from "ion-rangeslider"; // eslint-disable-line no-unused-vars

export default {
  name: "AppPoolBid",
  data: () => {
    return {
        rangeSlider: null,
        poolVolume: 0,
        rangeStep: 0.01,
        showBidModal: false,
        bidPrice: 0
    };
  },
  computed: mapState({
    pool: (state) => state.pool.data,
    isCompleted: (state) => (state.pool.data.state == 1),
    userBids: (state) => state.pool.bids,
    loggedIn: (state) => state.auth.status.loggedIn,
    lastShippingPrice: (state) => state.pool.data.last_shipping_price ?? 0,
    poolState: (state) => state.pool.data.state,
    isFollowed: (state) => state.pool.user_follows,
    unit: (state) => state.pool.data.product.unidad_medida,
    newBid: (state) => state.pool.new_bid
  }),
  methods: {
    closeModal() {
        this.showBidModal = false;
        this.lastPrice = this.price;
    },
    makeBid() {
      if (this.bidPrice == 0) {
        return false;
      }
      this.showBidModal = true;
    },
    getLastPrice() {
      if (this.$store.state.pool.data.last_price) {
        return this.$store.state.pool.data.last_price.price;
      }
      
      return this.$helpers.formatDecimalKeepingFloat(
        this.$store.state.pool.data.product.precio_mercado 
        * (100 - this.$store.state.pool.data.product.descuento_1) / 100
      );
    },
    subscribe() {
      this.$store.dispatch('pool/subscribe', this.pool.uuid);
    },
    nextStep() {
      // If user didn't touch the range bar
      if(!this.newBid.price) {
        this.$store.commit("pool/setNewPrice", (this.getLastPrice() - this.lastShippingPrice));
      }

      this.$emit("nextstep");
    },
    loadRangeSliders() {
        let self = this;

        this.rangeSlider.ionRangeSlider({
            skin: "round",
            type: "single",
            grid: true,
            hide_min_max: true,
            min: this.$helpers.formatDecimalKeepingFloat(self.getLastPrice() / 2),
            max: this.$helpers.formatDecimalKeepingFloat(self.getLastPrice() * 0.9),
            step: self.rangeStep,
            from: this.$helpers.formatDecimalKeepingFloat(self.getLastPrice() * 0.9),
            onChange: function (data) {
                self.bidPrice = data.from;
                self.$store.commit("pool/setNewPrice", data.from);
            },
        });
    }
  },
  mounted() {
    this.rangeSlider = $("#rangopool");
    this.loadRangeSliders();
    this.isFollowed = this.pool.user_follows;
  },
};
</script>