<template>
    <header id="header">
        <div class="header-body header-body-bottom-border-fixed box-shadow-none border-top-0">
            <div class="header-container container">
                <div class="header-row py-2">
                    <div class="header-column w-100">
                        <div class="header-row justify-content-between">
                            <div class="header-logo z-index-2 col-lg-2 px-0">
                                <router-link to="/">
                                    <img alt="Epooler" height="60" data-sticky-width="100" data-sticky-height="58" data-sticky-top="82" src="@/assets/img/logo.svg" />
                                </router-link>
                            </div>
                            <AppHeaderSearch></AppHeaderSearch>
                            <AppHeaderLogin></AppHeaderLogin>
                        </div>
                    </div>
                    <div class="header-column justify-content-end">
                        <div class="header-row"></div>
                    </div>
                </div>
            </div>
            <fixed-header>
                <AppHeaderNavbar></AppHeaderNavbar>
            </fixed-header>
        </div>
    </header>
</template>
<script>
    import AppHeaderNavbar from './AppHeaderNavbar';
    import AppHeaderSearch from './AppHeaderSearch';
    import AppHeaderLogin from './AppHeaderLogin';
    
    export default {
      name: 'AppHeader',
      components: {AppHeaderNavbar, AppHeaderSearch, AppHeaderLogin},
      props: {
        msg: String
      }
    }
</script>