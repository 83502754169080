<template>
  <div class="product pb-2 mb-0 bg-color-white pt-3">
      <div class="product-thumb-info mb-5">
          <div class="product-thumb-info-badges-wrapper esqcerrado">
              <span class="badge badge-ecommerce badget-cerrado">CERRADO</span>
          </div>
          <a href="resultado-busqueda.php">
              <div class="product-thumb-info-image">
                  <img alt="" class="img-fluid" :src="$helpers.apiAsset(pool.product.imagen_destacada)" />
              </div>
          </a>
     </div>
      
      <div class="d-flex justify-content-between bgcardpoolinactivo">
          <div class="row">
            <div class="col-12">
                <h3 class="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-3 ">   <a href="resultado-busqueda.php" class="text-white text-color-hover-cyan">{{ pool.product.nombre_comercial }}</a>
                </h3>
            </div>
            <div class="col-8 mb-1 text-white text-1">
                <i class="fas fa-percent text-white pr-1"></i> Ahorro
            </div>
            <div class="col-4 mb-1 text-white text-right text-1">{{ discount() }}%</div>
            <div class="col-8 mb-1 text-white text-1">
                <i class="fas fa-box-open text-white pr-1"></i> Unidades vendidas
            </div>
            <div class="col-4 mb-1 text-white text-right text-1">{{ Math.ceil(pool.volume) }} {{ $helpers.formatUnit(pool.product.unidad_medida) }}</div>
            <div class="col-8 mb-1 text-white text-1">
                <i class="fas fa-user-friends text-white pr-1"></i> Compradores
            </div>
            <div class="col-4 mb-1 text-white text-right text-1">{{ pool.buyers }}</div>
            <div class="col-8 mb-1 text-white text-1">
                <i class="far fa-clock text-white pr-1"></i> Días requeridos
            </div>
            <div class="col-4 mb-1 text-white text-right text-1">{{ requiredDays() }}</div>
            <div class="col-12 mt-1">
                <button v-on:click="goToPool()" class="btn btn-naranja btn-block m-0 mt-2">VER POOL</button>
            </div>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'AppPoolFinished',
  props: ['pool', 'colwidth'],
  data() {
    return {
        finished: false
    }
  },
  methods: {
    calculateDiscount() {
        const disc = (1 - this.pool.last_price.price / this.pool.product.precio_mercado) * 100;
        return Math.ceil(disc);
    },
    discount() {
        return (this.pool.last_price) ? this.calculateDiscount()
                                        : Math.ceil(this.pool.product.descuento_1);
    },
    goToPool() {
        this.$router.push({ path: `/pool/${this.pool.uuid}` });
    },
    requiredDays() {
        const from = this.pool.created_at;
        if (this.pool.date_finish === null) {
            return '-';
        }
        const to = this.pool.date_finish;
        let remaining_time = (new Date(to)) - (new Date(from));
        
        return Math.ceil(remaining_time / 1000 / 24 / 60 / 60);
    },
  },
}
</script>