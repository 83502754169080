import { httpClient } from './http-client';

class UserService 
{
    getProfile() {        
        const uri = 'user/profile/';
        return httpClient
                .get(uri);
    }

    updateProfile(data) {
        const uri = 'user/profile';
        return httpClient
                .post(uri, data);
    }

    getUserPools() {
        const uri = 'user/pools';
        return httpClient
                .get(uri);
    }

    getFollowedPools() {
        const uri = 'user/followed';
        return httpClient
                .get(uri);
    }

    register(data) {
        const uri = 'public/register';
        return httpClient
                .post(uri, data, { showError: false });
    }

    savePreferences(data) {
        const uri = 'public/user/preferences';
        return httpClient
                .post(uri, data);
    }

    getNotifications() {
        const uri = 'user/notifications';
        return httpClient
                .get(uri);
    }
}

export default new UserService();