/* eslint-disable no-undef */
<template>
    <div>
        <gmap-autocomplete class="form-control text-3 h-50p py-2"
          ref="input"
          @place_changed="setAddress"
          autocomplete="new-password"
          onfocus="this.setAttribute('autocomplete', 'new-password');"
          :options="autocompleteOptions"
          >
          <template v-slot:input="slotProps">
             <div>
                <input
                   :placeholder="placeholder"
                   ref="input"
                   required
                   v-on:listeners="slotProps.listeners"
                   v-on:attrs="slotProps.attrs"
                   />
             </div>
          </template>
        </gmap-autocomplete>

        <b-modal
          id="modal-prevent-closing"
          ref="modal"
          title="Confirma la dirección"
          v-model="showModal"
          hide-header
          no-close-on-esc
          no-close-on-backdrop
        >
            <gmap-map
                v-if="address != null" 
                :center="address.geometry.location" 
                :zoom="13"
                class="map-container"
                :clickable="true"
                :draggable="true"
            >
                <gmap-marker
                    v-if="address" 
                    :position="address.geometry.location" 
                    :clickable="true" 
                    :draggable="true" 
                    @click="center=address.geometry.location"
                    @dragend="changeLocation"
                ></gmap-marker>
            </gmap-map>

            <template #modal-footer>
                <div class="row">
                    <div class="col-12 mb-2">
                        <div class="text-center text-danger">
                            <i class="fas fa-map-marker-alt mr-1"></i>
                            <strong>Confirma tu ubicación</strong>
                        </div>
                        <strong>{{address.formatted_address}}</strong>
                    </div>
                    <div class="col-sm-12 text-center mb-2">
                        <!-- <i class="fas fa-map-marker-alt mr-1"></i>
                        <strong>Confirma tu ubicación</strong> -->
                        <p>Si la ubicacion no es correcta, arrastra el marcador en el mapa hasta encontrarla.</p> 
                    </div>
                    <div class="col-sm-12 text-center">
                        <button class="btn btn-naranja btn-modern text-uppercase" v-on:click.stop.prevent="confirm()">Confirmar</button>
                        <button class="btn btn-modern text-uppercase" v-on:click.stop.prevent="reject()">Corregir</button>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>
<script>
   export default {
        name: 'AppGoogleMapsAutocomplete',
        props: ['placeholder'],
        data() {
            return {
                address: null,
                autocompleteOptions: {
                    fields: ['place_id', 'geometry', 'formatted_address', 'address_components'],
                    componentRestrictions: {
                      country: [
                        'ar',
                      ],
                    }
                },
                showModal: false,
                geocoder: null
            }
        },
        methods: {
            confirm() {
                this.showModal = false;
                this.$emit('confirm', this.address);
            },
            reject() {
                this.showModal = false;
                this.$refs.input.$el.focus();
                return;
            },
            setAddress(place) {
                this.address = place;
                this.showModal = true;
            },
            changeLocation(event) {
                let self = this;
                this.geocoder.geocode({latLng: event.latLng}, 
                        (results, status)  =>
                        {
                            if (status == google.maps.GeocoderStatus.OK) 
                            {
                                self.address = results[0];
                                self.$refs.input.$el.value = self.address.formatted_address;
                            } 
                            else 
                            {
                                console.log('address not found');
                            }
                        }
                    );
            }
        },
        mounted() {
            this.$gmapApiPromiseLazy().then(() => {
                this.geocoder = new google.maps.Geocoder();
            });
        }
   }
</script>

<style type="text/css">
    .map-container {
        margin-top: 0px;
        height: 300px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    .modal-body {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .modal-footer {
        border-top: 0;
    }
</style>