<template>
  <section
        class="section section-default border-0 my-0 shop"
        v-bind:class="background"
        v-if="list.length > 0"
  >
        <div class="container products" v-if="list.length > 0 && data_loaded">
            <div class="row">
            <div class="col">
                <h4 class="cazul text-7 mb-4">
                    <i class="far fa-clock mr-2"></i>
                    <span>Productos</span>
                </h4>
                <carousel
                v-if="data_loaded"
                class="
                    owl-carousel owl-theme
                    show-nav-hover
                    owl-loaded owl-drag owl-carousel-init
                "
                :autoplay="false"
                :navText="['<', '>']"
                :margin="20"
                :loop="false"
                :nav="true"
                :dots="false"
                :responsive="{
                    0: { items: 1 },
                    479: { items: 1 },
                    768: { items: 1 },
                    979: { items: 4 },
                    1199: { items: 4 },
                }"
                >
                <app-pool-product
                    v-for="(product, index) in list"
                    :key="index"
                    :product="product"
                />
                </carousel>
            </div>
            </div>
        </div>
  </section>
</template>

<script>
import AppPoolProduct from "@/components/layout/common/AppPoolProduct";
import carousel from "vue-owl-carousel";
import PoolService from "@/services/pool-service";

export default {
  name: "AppHomeProductList",
  props: ['type', 'background'],
  components: { AppPoolProduct, carousel },
  data() {
    return {
        data_loaded: false,
        list: []
    };
  },
  mounted() {
    PoolService.list("featured_products").then((response) => {
      this.list = response.data.data;
      this.data_loaded = true;
    });
  }
};
</script>