<template>
  <div>
    <div class="row">
      <div class="col-sm-3 col-6" v-tooltip.auto="'Incluye costo de distribución'">
        <div class="cajaestados es1">
          <b>{{ priceUnit() }} {{ getPrice() }}</b>
          <br />
          <span v-if="this.pool.last_price && this.userType == 2">precio a mejorar</span>
          <span v-else-if="this.pool.last_price">precio actual</span>
          <span v-else>precio potencial</span>
          <i class="fas fa-dollar-sign"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es2">
          <b>{{ getPotentialDiscount() }}%</b>
          <br />
          <span v-if="this.pool.last_price">descuento</span>
          <span v-else>dto. potencial</span>
          <i class="fas fa-percent"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es3">
          <b>{{ pool.buyers }}</b
          ><br />compradores <i class="fas fa-user-friends"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es3" v-tooltip.auto="'Incluye costo de distribución'">
          <b>{{ priceUnit() }} {{ $helpers.formatNumber(pool.product.precio_mercado) }}</b
          ><br />precio mercado
          <i class="fas fa-chart-line"></i>
        </div>
      </div>
    </div>
    <hr />
    <!-- SECOND ROW DATA -->
    <div class="row">
      <div class="col-4 text-center ptarget" title="">
        <h4 class="mt-2">
          <i class="fas fa-unlock-alt text-success"></i>
          Volumen alcanzado
        </h4>
      </div>
      <div class="col-4 text-center pmercado">
        <b>{{
            $helpers.formatNumber(pool.product.masa_critica_1 - pool.volume)
          }}
          {{ unit }}</b>
        <br />Volumen restante
      </div>
      <div class="col-4 text-center pepooler">
        <b>{{ $helpers.formatNumber(pool.product.masa_critica_1) }}
          {{ unit }}</b>
        <br />Volumen a alcanzar
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "AppPoolDataPanelCompleted",
    methods: {
        getPrice() {
            if(!this.pool.last_price) {
              const potencial_price = this.pool.product.precio_mercado * ((100 - this.pool.product.descuento_1) / 100);
              return this.$helpers.formatDecimal(potencial_price);
            }
            return this.$helpers.formatDecimal(this.pool.last_price.price);
        },
        priceUnit() {
          return this.$helpers.getPriceUnit(this.pool);
        },
        getPotentialDiscount() {
            if(!this.pool.last_price) {
              return Math.ceil(this.pool.product.descuento_1);
            }else{
              const disc = (1 - this.pool.last_price.price / this.pool.product.precio_mercado) * 100;
              return Math.ceil(disc);
            }
        }
    },
    computed: mapState({
        pool: (state) => state.pool.data,
        unit: (state) => state.pool.data.product.unidad_medida,
        newPrice: (state) => state.pool.new_bid.price,
        userType: (state) => {
          return (state.auth.user) ? state.auth.user.user_data.type : 0
        },
    })
};
</script>
