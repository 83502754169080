<template>
  <div class="row">
    <div id="stepper1" class="col bs-stepper">
      <div class="bs-stepper-header" role="tablist">
        <div
          class="step"
          data-target="#test-l-1"
          v-bind:class="{ active: (!shippingstep && !detailstep && !bidsuccessful) }"
        >
          <button
            type="button"
            class="step-trigger"
            role="tab"
            id="pasooferta"
            aria-controls="test-l-1"
          >
            <span class="bs-stepper-circle"
              ><i class="fas fa-dollar-sign"></i
            ></span>
            <span class="bs-stepper-label">Oferta</span>
          </button>
        </div>
        <div class="bs-stepper-line"></div>
        <div
          class="step"
          data-target="#test-l-2"
          v-bind:class="{ active: shippingstep }"
        >
          <button
            type="button"
            class="step-trigger"
            role="tab"
            id="pasodistribucion"
            aria-controls="test-l-2"
          >
            <span class="bs-stepper-circle"
              ><i class="fas fa-map-marked-alt"></i
            ></span>
            <span class="bs-stepper-label">Distribucion</span>
          </button>
        </div>
        <div class="bs-stepper-line"></div>
        <div class="step" data-target="#test-l-3" v-bind:class="{ active: detailstep && !bidsuccessful}">
          <button
            type="button"
            class="step-trigger"
            role="tab"
            id="pasdodetalle"
            aria-controls="test-l-3"
          >
            <span class="bs-stepper-circle"
              ><i class="fas fa-info-circle"></i
            ></span>
            <span class="bs-stepper-label">Detalle</span>
          </button>
        </div>
        <div class="bs-stepper-line"></div>
        <div class="step" data-target="#test-l-4" v-bind:class="{ active: bidsuccessful}">
          <button
            type="button"
            class="step-trigger"
            role="tab"
            id="pasdofinal"
            aria-controls="test-l4"
          >
            <span class="bs-stepper-circle"
              ><i class="fas fa-check-square"></i
            ></span>
            <span class="bs-stepper-label">Finalizar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AppPoolStepper",
  props: ["pool", "shippingstep", "detailstep", "bidsuccessful"],
  data: () => {
    return {};
  },
};
</script>
<style scoped>
.bs-stepper-header {
  background-color: #f7f7f7;
  margin-bottom: 20px;
  border-radius: 4px;
}

.active .bs-stepper-circle {
  background-color: #e4522c;
}

.bs-stepper .step-trigger:focus {
  color: #e4522c;
}
</style>