<template>
    <div role="main" class="main shop pt-4">
        <div class="container">
            <div class="row">
                <div class="col">
                    <ul class="breadcrumb bd-block text-4 mb-4">
                        <li>
                            <a href="#" class="text-color-default text-color-hover-primary text-decoration-none"><i class="fas fa-home"></i></a>
                        </li>
                        <li><a href="#" class="text-color-default text-color-hover-primary text-decoration-none">Categorías</a></li>
                        <li>{{ getCategoryName() }}</li>
                        <li v-if="type == 'subcategory'">{{ getSubcategoryName() }}</li>
                    </ul>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3 order-2 order-lg-1">
                    <aside class="sidebar">
                        <h5 class="font-weight-semi-bold pt-3">Categorias</h5>
                        <ul class="nav nav-list flex-column" >
                            <li class="nav-item" v-for="(category, index) in categories" :key="index">
                                <router-link :to="{ path: '/list/category/' + category.id }" class="nav-link" v-bind:class="{'font-weight-bold': (category_name==category.name) }">
                                    {{ category.name }}
                                </router-link>
                                <b-collapse accordion="categories-accordion" :id="'subcat' + category.id">
                                    <ul class="nav nav-list flex-column">
                                        <li class="nav-item" v-for="(subcat, idx) in category.subcat" :key="idx">
                                            <router-link :to="{ path: '/list/subcategory/' + subcat.id }" class="nav-link" v-bind:class="{'font-weight-bold': (subcategory_name==subcat.nombre) }">
                                                {{ subcat.nombre }}
                                            </router-link>
                                        </li>
                                    </ul>
                                </b-collapse>
                            </li>
                        </ul>
                    </aside>
                </div>
                <div class="col-lg-9 order-1 order-lg-2 product-list">
                    <h4 class="cazul text-7 text-center" v-if="products.length == 0 ">Lo sentimos, aún no tenemos productos en esta categoría.</h4>
                    <div v-else class="row products product-thumb-info-list">
                        <app-pool-in-process v-for="(pool, index) in pools" :key="index" :pool="pool" colwidth="col-sm-4" />
                        <app-pool-product v-for="(product, index) in products" :key="index" :product="product" colwidth="col-sm-4" />
                    </div>
                    <div class="row mt-4" v-if="pagination">
                        <div class="col">
                            <ul class="pagination float-right">
                                <li class="page-item"><a class="page-link" href="#"><i class="fas fa-angle-left"></i></a></li>
                                <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <a class="page-link" href="#"><i class="fas fa-angle-right"></i></a>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import AppPoolInProcess from '@/components/layout/common/AppPoolInProcess.vue';
    import CategoryService from '@/services/category-service';
    import { mapState } from 'vuex';
    import AppPoolProduct from '../components/layout/common/AppPoolProduct.vue';
    
    export default {
        name: 'List',
        props: ['type', 'id'],
        components: {AppPoolInProcess, AppPoolProduct},
        data() {
            return {
                pagination: false,
                amount: 9,
                pools: [],
                products: [],
                category_name: '',
                category_id: 0,
                subcategory_name: '',
            }
        },
        beforeRouteUpdate(to, from, next) {
            this.getPoolsByCategory(to.params.type, to.params.id);
            next();
        },
        computed: mapState({  
            categories: state => state.categories.categories,
        }),
        methods: {
            getCategoryName() {
                if(this.type == 'category') {
                    for(let cat of this.categories) {
                        if(this.id == cat.id) {
                            this.category_name = cat.name;
                            this.category_id = cat.id;
                            return cat.name;
                        }
                    }
                }else{
                    for(let cat of this.categories) {
                        for(let subcat of cat.subcat) {
                            if(this.id == subcat.id) {
                                return cat.name;
                            }
                        }
                    }
                }
            },
            getSubcategoryName() {
                for(let cat of this.categories) {
                    for(let subcat of cat.subcat) {
                        if(this.id == subcat.id) {
                            this.category_id = cat.id;
                            this.category_name = cat.name;
                            this.subcategory_name = subcat.nombre;
                            return subcat.nombre;
                        }
                    }
                }
            },
            getPoolsByCategory(type, id) {
                const subcat = (type == 'subcategory') ? true : false;
                CategoryService.getPoolsAndProductsByCategory(id, subcat, this.amount)
                    .then((response) => {
                        this.pools = response.data.data.pools;
                        this.products = response.data.data.products;
                    });
            }
        },
        mounted() {
            switch(this.type) {
                case 'category':
                case 'subcategory':
                    this.getPoolsByCategory(this.type, this.id);
                    break;
                default:
                    this.$router.push({ name: 'Home' });
                    break;
            }
        }
    }
</script>