// plugins/Constants.js
let Constants = {
  //  install
  install(Vue) {
    //  create global Constants
    Vue.Constants = {
      API_ERROR: "Network Error",
    };
  }
};

export default Constants;