<template>
   <div class="header-nav-features header-nav-features-no-border col-lg-7 px-0 ml-0 d-none d-sm-block">
       <div class="header-nav-feature">
           <form role="search" action="#" method="get">
               <div class="search-form-wrapper input-group" id="the-basics">
                  <vue-typeahead-bootstrap
                      class="col-md-12"
                      input-class=""
                      v-model="query"
                      :ieCloseFix="false"
                      :data="results"
                      :serializer="formatResult"
                      @hit="selectProduct"
                      placeholder="Buscar..."
                      @input="searchProducts"
                      min-matching-chars=2
                      show-all-results=true
                   >
                        <template slot="suggestion" slot-scope="{ data, htmlText }">
                          <div class="d-flex align-items-center" v-if="noresults">
                            No se ha encontrado el producto
                          </div>
                          <div class="d-flex align-items-center"  v-else>
                            <img
                              class="rounded-circle"
                              :src="$helpers.apiAsset(data.image)"
                              style="width: 40px; height: 40px;"
                            />
                            <span class="ml-4" v-html="htmlText"></span>
                            <i class="ml-auto fas fa-arrow-right fa-2x"></i>
                          </div>
                        </template>
                  </vue-typeahead-bootstrap>
               </div>
           </form>
       </div>
   </div>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import PoolService from '@/services/pool-service';

export default {
    name: 'AppHeaderSearch',
    components: {VueTypeaheadBootstrap},
    data() {
        return {
            query: '',
            selecteduser: null,
            results: [],
            noresults: true
        }
    },
    methods: {
        searchProducts: function() {
            if(this.query.length < 3) {
                return false;
            }
            this.results = [];
            this.noresults = true;

            this.$store.state.products.products.forEach(element => {
                // @todo backend search
                if(
                    element.name.toLowerCase().search(this.query.toLowerCase()) > -1 ||
                    element.company.toLowerCase().trim().search(this.query.toLowerCase()) > -1
                ) {
                    this.results.push(element);
                    this.noresults = false;
                }
            });

            if(this.noresults) {
                this.results = [{'name': 'No se ha encontrado ningún producto', 'id': 0, 'image':'bla'}];
            }
        },
        selectProduct: function(product) 
        {
            const location = this.$store.state.auth.location;
            const location_data = (location) ? {lat: location.coords.latitude, lon: location.coords.longitude} : null;
            const token = this.$store.getters['auth/getUserToken'];

            PoolService.search(product.id, location_data, token)
                .then(
                    data => {
                        window.location.href = '/pool/' + data.pool_uuid;
                    }
                )
            ;
        },
        formatResult: function (item) 
        {
            return item.company !== '' ? item.company + ' > ' + item.name : item.name;
        },
    },
    mounted() {
        this.$store.dispatch('products/getAll');
        this.$store.dispatch('auth/getUserLocation');
    }
}
</script>

<style type="text/css">
    .vbt-autcomplete-list {
        padding-top: 0 !important;
    }
</style>

