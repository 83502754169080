import axios from 'axios';
import errorResponseHandler from './error/error-handler';
import authHeader from '@/services/auth-header';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from '@/store';

/** Default config for axios instance */
let config = {
    baseURL: process.env.VUE_APP_API_BASEURL,
    showError: true, // display toastr with error or not
    refreshToken: '', // used to set refresh token header (only for refresh token purposes)
};

/** Creating the instance for axios */
const httpClient = axios.create(config);

/** Auth token interceptors */
const authInterceptor = config => {
    config.headers = authHeader();
    if (config.refreshToken !== '') {
        config.headers.Refreshtoken = config.refreshToken;
    }
    return config;
};

/** logger interceptors */
const loggerInterceptor = config => {
  /** TODO */
  return config;
}

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

createAuthRefreshInterceptor(httpClient, (failedRequest) => {
    if(!store.getters['auth/isLogged']) {
        return Promise.reject(failedRequest);
    }
    return store.dispatch('auth/refreshToken')
        .catch(() => {
            store.dispatch('auth/logout');
            return Promise.reject();
        })
        .then(() => {
            return Promise.resolve();
        })
});

httpClient.interceptors.response.use(
   response => response,
   errorResponseHandler
);

export { httpClient };