import { httpClient } from './http-client';

class CategoryService {
    getAll() {
        return httpClient
          .get('public/categories/get')
          .then(response => {
              return response.data;
          });
    }

    getPoolsAndProductsByCategory(category_id, subcat = false, amount = 9) {
        const type = (subcat) ? 'subcategory' : 'category';
        let uri = 'public/pool/list/';
        uri += '?criteria=' + type;
        uri += '&amount=' + amount;
        uri += '&id=' + category_id;
        
        return httpClient
                .get(uri);
    }
}

export default new CategoryService();