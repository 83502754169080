
class ErrorMessages  {
    errors = {
        DEFAULT_ERROR: 'Hubo un error. Intente luego.',
        POOL_BID_INVALID_POOL: 'Hubo un error al enviar la oferta.',
    };
    getMessage(error_code = 'DEFAULT') {
        if(this.errors.hasOwnProperty(error_code)) {
            return this.errors[error_code];
        }
        return this.errors.DEFAULT_ERROR;
    }
};
export default new ErrorMessages();