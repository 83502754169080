<template>
   <div>
      <i class="far fa-clock"></i>&nbsp;
      <vue-countdown v-if="!finished" :time="getRemainingTime(end)" v-slot="{ days, hours, minutes, seconds }" @end="finishPool">
        <span v-if="days >= 1"><b>{{ days }}</b> días </span>
        <span v-if="days == 0 && hours > 1"><b>{{ hours }}</b> horas </span>
        <span v-if="days == 0 && hours == 0 && minutes > 1"><b>{{ minutes }}</b> minutos </span>
        <span v-if="days == 0 && hours == 0 && minutes == 0 && seconds > 1"><b>{{ seconds }}</b> seconds </span>
                                    restantes
      </vue-countdown>
      <b v-else>POOL FINALIZADO</b>
   </div>
</template>

<script>

import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: 'AppPoolCountdown',
  components: {VueCountdown},
  props: ['end', 'isfinished'],
  data () {
    return {
        finished: false
    }
  },
  watch: { 
    isfinished: function(newVal) {
        this.finished = newVal;
    }
  },
  methods: {
    getRemainingTime: to => {
        let remaining_time = (new Date(to)) - (new Date());
        if(remaining_time < 0) {
            return false;
        }
        return remaining_time;
    },
    finishPool() {
        this.finished = true;
    }
  },
  mounted() {
    this.finished = this.isfinished;
  }
}
</script>