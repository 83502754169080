export default { 
    apiAsset(uri) {
        return process.env.VUE_APP_ASSET_BASEURL + uri;
    },
    formatUnit(unit) {
        switch(unit) {
            case 'kilos':
                return 'kg';
            case 'litros':
                return 'lts';
            case 'unidades':
                return 'uni';
        }
    },
    timeLeftString(to) {
        // format must be YYYY-MM-DD HH:II:SS
        let to_date = new Date(to);
        let today = new Date();
        const diffTime = to_date - today;

        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        if(diffDays < 1) {
            const diffHours = diffTime / (1000 * 60 * 60);
            if(diffHours < 1) {
                const diffMinutes = diffTime / (1000 * 60);
                return Math.ceil(diffMinutes) + ' minutos restantes';
            }
            return Math.ceil(diffHours) + ' horas restantes';
        }
        return Math.ceil(diffDays) + ' días restantes';
    },
    formatNumber(n) {
        return new Intl.NumberFormat('de-DE').format(n);
    },
    formatDecimal(n) {
        return new Intl.NumberFormat('de-DE').format(n.toFixed(2));
    },
    formatDecimalKeepingFloat(n) {
        return Math.round(n * 100) / 100;
    },
    isFinished(end_date) {
        const to_date = new Date(end_date);
        const utc = new Date().toISOString();
        const utc_time = new Date(utc);

        const diffTime = to_date - utc_time;

        return (diffTime <= 0);
    },
    getRemainingTime: (to) => {
        let remaining_time = new Date(to) - new Date();
        if (remaining_time < 0) {
          return false;
        }
        return remaining_time;
    },
    getPotentialPriceFromProduct: (productData) => {
        return Number((
            productData.precio_mercado * 
            ((100 - productData.descuento_1) / 100)).toFixed(1)
        );
    },
    getPotentialPrice: (poolData) => {        
        return Number((
            poolData.product.precio_mercado * 
            ((100 - poolData.product.descuento_1) / 100)).toFixed(1)
        );
    },
    // @todo use this method everytime we need to get the price unit
    getPriceUnit: (poolData) => {
        return poolData.product.moneda.toUpperCase();  
    },
};
