import CategoryService from '@/services/category-service';

export const categories = {
    namespaced: true,
    state: {
        categories: [],
    },
    actions: {
        getAll({ commit }) {
          return CategoryService.getAll().then(
            categories => {
                commit('getAll', categories);
                return Promise.resolve(categories);
            }
          );
        }
    },
    mutations: {
        getAll(state, data) {
            state.categories = data;
        }
    },
    getters: {
        getAll: state => {
            return state.categories
        },
    }
};
