<template>
  <form
    enctype="multipart/form-data"
    method="post"
    class="cart ofertform"
    action="#"
  >

    <!-- FINISHED POOL -->
    <div class="row" v-if="poolState == 2">
      <div class="col-12">
        <h4 class="cazul text-7">
          <i class="fas fa-check-circle mr-2"
            v-bind:class="{'text-success':pool.last_price}"
          ></i>
          Pool finalizado
        </h4>
        <p v-if="!pool.last_price">
          Lamentablemente no se ha podido conseguir una oferta para este pool.
        </p>
      </div>
    </div>

    <div class="row" v-else-if="isCompleted">
      <div class="col-md-12" v-if="userBids.count > 0">
        <div class="alert alert-warning" role="alert">
            <p class="ofertaxtarget text-success">
              <i class="fas fa-money-check-alt text-success"></i>
              <b>Tu última oferta es de ${{ userBids.last_price }} 
                + ${{ userBids.last_shipping_price }} (distribución)</b>
            </p>
        </div>
      </div>

      <div class="col-12">
        <p class="ofertaxtarget">
          <b>Selecciona el precio de <b>distribucion</b> por {{ $helpers.formatUnit(unit) }}</b>
        </p>
      </div>

      <div class="col-sm-9 col-7">
        <input
          type="text"
          class="js-range-slider-2"
          id="rangopool-2"
          v-model="shipping_price"
        />
      </div>
      <div class="col-sm-3 col-5 text-right">
        <a class="btn btn-naranja btn-modern text-uppercase" @click="makeBid"
          >SIGUIENTE</a
        >
      </div>
    </div>

    <app-pool-bid-modal
        v-if="loggedIn"
        :show="showBidModal"
        v-on:close="closeModal"
    />
  </form>
</template>
<script>
import { mapState } from "vuex";
import AppPoolBidModal from "@/components/layout/pool/AppPoolBidModal";
import $ from "jquery";
import ionRangeSlider from "ion-rangeslider"; // eslint-disable-line no-unused-vars

export default {
  components: { AppPoolBidModal },
  name: "AppPoolBidShipping",
  data: () => {
    return {
        rangeSlider: null,
        poolVolume: 0,
        rangeStep: 0.01,
        showBidModal: false,
        shipping_price: 0,
        step: 1,
        initialPrice: 0
    };
  },
  computed: mapState({
    pool: (state) => state.pool.data,
    isCompleted: (state) => (state.pool.data.state == 1),
    userBids: (state) => state.pool.bids,
    loggedIn: (state) => state.auth.status.loggedIn,
    lastPrice: (state) => state.pool.data.last_price ? state.pool.data.last_price.price : state.pool.data.product.precio_mercado,
    poolState: (state) => state.pool.data.state,
    isFollowed: (state) => state.pool.user_follows,
    unit: (state) => state.pool.data.product.unidad_medida,
    newBid: (state) => state.pool.new_bid
  }),
  methods: {
    closeModal() {
        this.showBidModal = false;
        this.lastPrice = this.shipping_price.price;
    },
    bidData() {
      this.new_bid.pool = this.pool;
      return this.new_bid;
    },
    makeBid() {
      this.$emit("nextstep");
    },
    getMaxPrice() {
      return (this.lastPrice - this.newBid.price).toFixed(2);
    },
    loadRangeSliders() {
        let self = this;
        this.initialPrice = (this.lastPrice - this.newBid.price) / 2;
        this.$store.commit("pool/setNewShippingPrice", this.initialPrice);

        this.rangeSlider.ionRangeSlider({
            skin: "round",
            type: "single",
            grid: true,
            hide_min_max: true,
            min: 0.01,
            max: this.getMaxPrice(),
            step: this.rangeStep,
            from: this.initialPrice,
            onChange: function (data) {
                self.$emit("changeshippingprice", data.from);
                self.shipping_price = data.from;
                self.$store.commit("pool/setNewShippingPrice", data.from);
            },
        });
    }
  },
  mounted() {
    this.rangeSlider = $("#rangopool-2");
    this.loadRangeSliders();
    this.isFollowed = this.pool.user_follows;
  }
};
</script>