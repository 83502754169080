import ProductService from '@/services/product-service';

export const products = {
    namespaced: true,
    state: {
        products: []
    },
    actions: {
        getAll({ commit }) {
          return ProductService.getAll().then(
            products => {
                commit('getAll', products);
                return Promise.resolve(products);
            }
          );
        }
    },
    mutations: {
        getAll(state, data) {
            state.products = data;
        }
    },
    getters: {
        getAll: state => {
            return state.products
        }
    }
};
