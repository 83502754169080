<template>
    <b-modal
        id="modal-prevent-closing"
        ref="modal"
        title="Confirmar Reserva"
        v-model="show"
        @hide="closeModal"
        size="lg"
        >
        <div slot="modal-title">
            <h4 class="modal-title text-capitalize" id="formModalLabel">Confirmar Reserva</h4>
        </div>

        <div v-if="totalReservation && !reservation_confirmed" class="alert alert-warning" role="alert">
            <p class="ofertaxtarget text-success">
                <b>Recordá que ya tenés una reserva por {{ totalReservation }} 
                    {{ $helpers.formatUnit(reservation.pool.product.unidad_medida) }}
                </b>
            </p>
        </div>
        <div class="p-4 mt-2" v-if="reservation_confirmed">
            <!-- @TODO sacar este estilo inline y ponerlo donde corresponde-->
            <h2 class="alert alert-success" style="font-size:18px;">
                Bienvenido al pool de compra de 
                <strong>{{ reservation.pool.product.nombre_comercial }}</strong>,
                lo mantendremos al tanto.
            </h2>
        </div>
        <table class="table" v-else>
            <tbody>
                <tr class="text-center">
                    <th class="product-col" colspan="2"></th>
                    <th class="price-col">Precio potencial (*)</th>
                    <th class="qty-col">Cantidad</th>
                    <th class="total-col">Total (*)</th>
                </tr>
                <tr>
                    <td>
                        <img class="img-fluid maxw-64" :src="$helpers.apiAsset(reservation.pool.product.imagen_destacada)" alt="Project Image" />
                    </td>
                    <td class="tdnombre">
                        {{ reservation.pool.product.nombre_comercial }}
                    </td>
                    <td class="tdprecio">
                        {{ priceUnit() }} {{ $helpers.formatNumber($helpers.getPotentialPrice(pool)) }}
                        <span class="cgris"> por {{ $helpers.formatUnit(reservation.pool.product.unidad_medida) }}</span>
                    </td>
                    <td class="tdprecio">
                        {{ $helpers.formatNumber(reservation.amount) }}
                        <span class="cgris"> {{ $helpers.formatUnit(reservation.pool.product.unidad_medida) }}</span>
                    </td>
                    <td class="tdcomprdores">
                        {{ priceUnit() }} {{ $helpers.formatNumber(getTotalPrice()) }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pl-2 mx-2">
            <p>Trabajaremos para conseguir el mejor precio posible.<br>
            Epooler te garantiza que el precio final será menor a <strong>{{ priceUnit() }} {{ $helpers.formatNumber(pool.product.precio_mercado) }}</strong>
            y será cercano al precio potencial <strong>({{ priceUnit() }} {{ $helpers.formatNumber($helpers.getPotentialPrice(pool)) }})</strong>
            aplicándose el descuento de <strong>{{ pool.product.descuento_1 }}%</strong>
            </p>
        </div>
        <template #modal-footer>
            <div class="pull-left">
                <strong>(*)</strong> El precio potencial es indicativo y puede variar.
            </div>
            <button
                v-if="loading"
                class="btn btn-naranja btn-modern text-uppercase float-right" disabled
            >
                Espere un momento...
            </button>
            <button
                v-else-if="!reservation_confirmed"
                class="btn btn-naranja btn-modern text-uppercase float-right"
                v-on:click.stop.prevent="makeReservation()"
            >
                Confirmar Reserva
            </button>
            <button
                v-if="reservation_confirmed"
                class="btn btn-naranja btn-modern text-uppercase float-right"
                v-on:click="closeModal()"
            >
                Cerrar
            </button>
            <div
                v-if="error_message != ''"
                class="text-danger font-weight-bold"
            >
                {{ error_message }}
            </div>
        </template>
    </b-modal>
</template>
<script>
    import { mapState } from "vuex";

    export default {
        name: 'AppPoolReservationModal',
        props: ['show', 'reservation'],
        data() {
            return {
                loading: false,
                error_message: '',
                reservation_confirmed: false,
            }
        },
        computed: mapState({
            reservations: (state) => state.pool.reservations,
            totalReservation: (state) => state.pool.reservations
                ? state.pool.reservations.total_amount 
                : 0,
            pool: (state) => state.pool.data,
        }),
        methods: {
            closeModal() {
                this.reservation_confirmed = false;
                this.$emit('close');
                this.$refs["modal"].hide();
            },
            getTotalPrice() {
                return (this.reservation.amount * this.pool.product.precio_mercado * (100 - this.pool.product.descuento_1) / 100)
                    .toFixed(1)
                ;
            },
            makeReservation() {
                this.loading = true;
                this.$store.dispatch('pool/makeReservation', {
                    pool_uuid: this.reservation.pool.uuid,
                    amount: this.reservation.amount
                }).then(() => {
                    this.loading = false;
                    this.reservation_confirmed = true;
                })
                .catch(error => {
                    this.error_message = error.error_msg;
                })
            },
            priceUnit() {
                return this.$helpers.getPriceUnit(this.pool);
            }
        }
    }
</script>
