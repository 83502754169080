<template>
    <div class="row">
        <div class="col-sm-12" v-if="successful">
            <!-- @TODO sacar este estilo inline y ponerlo donde corresponde-->
            <h2 class="alert alert-success" style="font-size:18px;">
                <i class="fas fa-check-circle mr-2 text-success"></i>
                Oferta realizada con éxito
            </h2>
        </div>
        <div class="col-sm-12" style="background-color:#f7f7f7;padding:20px" v-else>
            <h4>Detalles de la oferta</h4>
            <table class="table">
                <tbody>
                    <tr class="text-center">
                        <th class="price-col">Precio</th>
                        <th class="price-col">Precio Distribucion</th>
                        <th class="price-col">Precio Total</th>
                        <th class="qty-col">Volumen</th>
                        <th class="total-col">Total</th>
                    </tr>
                    <tr>
                        <td class="tdprecio">
                            ${{ $helpers.formatNumber(newBid.price) }}
                            <span class="cgris"> por {{ $helpers.formatUnit(pool.product.unidad_medida) }}</span>
                        </td>
                        <td class="tdprecio">
                            ${{ $helpers.formatNumber(newBid.shipping_price) }}
                            <span class="cgris"> por {{ $helpers.formatUnit(pool.product.unidad_medida) }}</span>
                        </td>
                        <td class="tdprecio">
                            ${{ $helpers.formatNumber(newBid.price + newBid.shipping_price) }}
                            <span class="cgris"> por {{ $helpers.formatUnit(pool.product.unidad_medida) }}</span>
                        </td>
                        <td class="tdprecio">
                            {{ $helpers.formatNumber(pool.volume) }}
                            <span class="cgris"> {{ $helpers.formatUnit(pool.product.unidad_medida) }}</span>
                        </td>
                        <td class="tdcomprdores">
                            $ {{ $helpers.formatNumber(pool.volume * (newBid.price + newBid.shipping_price)) }}
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <div v-if="error_message != ''" class="text-danger font-weight-bold">
                    {{ error_message }}
                </div>
                <button v-else-if="loading" class="btn btn-naranja btn-modern text-uppercase" disabled>Espere un momento...</button>
                <button v-else class="btn btn-naranja btn-modern text-uppercase" v-on:click.stop.prevent="makeBid()">Confirmar Oferta</button>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapState } from "vuex";

    export default {
        name: 'AppPoolBidDetail',
        data() {
            return {
                loading: false,
                error_message: '',
                errors: {
                    invalid_amount: 'El precio total no puede ser mayor al de la ultima oferta.'
                },
                successful: false
            }
        },
        computed: mapState({
            newBid: (state) => state.pool.new_bid,
            pool: (state) => state.pool.data
        }),
        methods: {
            makeBid() {
                if(this.loading) {
                    return;
                }
                let self = this;
                this.loading = true;
                this.$store.dispatch('pool/makeBid', {
                    pool_uuid: this.pool.uuid,
                    price: this.newBid.price,
                    shipping_price: this.newBid.shipping_price
                })
                .then(() => {
                        self.error_message = '';
                        self.loading = false;
                        self.successful = true;
                        self.$emit('bidsuccessful');
                    },
                    error => {
                        self.loading = false;
                        if(error.error_code == 'POOL_BID_INVALID_AMOUNT') {
                            self.error_message = self.errors.invalid_amount;
                        }
                    }
                );
            }
        }
    }
</script>
