import { httpClient } from './http-client';

class AuthService {
  login(user) {
    return httpClient
      .post('public/login', {
          email: user.email,
          password: user.password
      }, { showError: false } )
      .then(response => {
        if (response && response.data.token) {
            localStorage.setItem('user', JSON.stringify(response.data));
            return response.data;
        }
      });
  }

  refresh(refreshToken) {
    return httpClient
      .post(
        'public/token/refresh', {}, 
        { refreshToken: refreshToken, showError: false, auth: false}
      )
      .then(response => {
        if (response && response.data.token && response.data.refresh_token) {
            let user_data = (localStorage.getItem('user'))
                ? JSON.parse(localStorage.getItem('user')).user_data 
                : null;
            let new_data = response.data;
            new_data.user_data = user_data;
            localStorage.setItem('user', JSON.stringify(new_data));
            
            return response.data;
        }
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return httpClient.post('signup', {
        username: user.username,
        email: user.email,
        password: user.password
    });
  }

  getAdminUrl() {
    return process.env.VUE_APP_API_BASEURL + 'admin/';
  }
}
export default new AuthService();