/* eslint-disable no-mixed-spaces-and-tabs */
import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './modules/auth-module';
import { categories } from './modules/category-module';
import { products } from './modules/product-module';
import { pool } from './modules/pool-module';
import { user } from './modules/user-module';

Vue.use(Vuex);

Vue.config.devtools = true;

export default new Vuex.Store({
    modules: {
        auth, categories, products, pool, user
    }
});