<template>
  <div class="header-nav-feature header-nav-features-cart d-inline" v-if="loaded">
    <a
      href="#"
      class="header-nav-features-toggle"
      v-on:click="showNotifications = !showNotifications"
    >
      <i class="far fa-bell text-color-white"></i>
      <span class="cart-info">
        <span class="cart-qty">{{ notifications.length }}</span>
      </span>
    </a>
    <div
        class="header-nav-features-dropdown"
        v-bind:class="{'show': showNotifications}"
    >
        <div v-if="!notifications.length">
            <strong>No hay notificaciones</strong>
        </div>
        <div
            v-else
            class="alert alert-info alert-dismissible" role="alert"
            v-for="(notification, index) in notifications" :key="index"
            style="cursor:pointer"
            @click="goTo(notification.data)"
        >
            <!-- <button
            type="button"
            class="close"
            v-on:click="showNotifications = false"
            aria-label="Close"
            >
                <span aria-hidden="true"> ×</span>
            </button> -->
            <!-- <strong> -->
                <!-- <i class="far fa-check-square"> </i> -->
                    {{ notification.data.message }}
            <!-- </strong> -->
        </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "AppHeaderNotifications",
    data() {
        return {
            showNotifications: false,
            loaded: false
        }
    },
    methods: {
        goTo(data) {
            window.location.href = '/pool/' + data.pool_uuid;
        }
    },
    computed: mapState({
        notifications: (state) => state.user.notifications
    }),
    mounted() {
        this.$store.dispatch('user/getNotifications')
            .then(() => {
                this.loaded = true;
            })
        ;
    }
};
</script>