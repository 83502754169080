<template>
  <carousel id="sliderprincipal" class="nav-inside show-nav-hover dots-inside mb-0" :autoplay="false" :autoplayTimeout="5000" :autoplayHoverPause="false" :navText="['<','>']" :loop="true" :nav="true" :dots="true" :animateOut="fadeOut" :items="1">
      <div class="item">
         <img src="@/assets/img/slides/slide-1.jpg">
         <div class="cover">
            <div class="container">
               <div class="header-content">
                  <h1>UNETE A LA COMUNIDAD DE EPOOLER!</h1>
                  <h4>EPOOLER CONECTA GRANDES Y PEQUEÑOS COMPRADORES, VENDEDORES Y TRANSPORTISTAS DEL AGRO ARGENTINO EN TODO EL PAÍS</h4>
               </div>
            </div>
         </div>
      </div>
  </carousel>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'AppHomeSlider',
  components: {carousel},
  props: {
    msg: String
  }
}
</script>