<template>
    <div role="main" class="main">
        <section class="page-header page-header-modern bg-color-light-scale-1 page-header-lg bg-top-micuenta">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 align-self-center p-static order-2 text-center">
                        <h1 class="font-weight-bold text-light">
                            Mi Cuenta
                        </h1>
                    </div>
                    <div class="col-md-12 align-self-center order-1">
                        <ul class="breadcrumb d-block text-center cblanco">
                            <li>
                                <a href="#" class="cblanco">
                                <i class="fas fa-home"> </i>
                                </a>
                            </li>
                            <li class="active">
                                Mi Cuenta
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <div class="container pt-3 pb-2">
            <div class="row pt-2">
                <div class="col-lg-3 mt-4 mt-lg-0">
                    <div class="d-flex justify-content-center mb-4">
                        <div class="profile-image-outer-container">
                            <div class="profile-image-inner-container bg-color-primary">
                                <img src="@/assets/img/avatars/avatar.jpg" />
                                <span class="profile-image-button bg-color-dark">
                                <i class="fas fa-camera text-light"> </i>
                                </span>
                            </div>
                            <input type="file" id="file" class="profile-image-input" />
                        </div>
                    </div>
                    <aside class="sidebar mt-2" id="sidebar">
                        <ul class="nav nav-list flex-column mb-5">
                            <li class="nav-item">
                                <router-link to="/account/user" class="nav-link" v-bind:class="{active: (activeTab == 'user') }">Mis Datos</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/account/pools" class="nav-link" v-bind:class="{active: (activeTab == 'pools') }">Mis Pooles</router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/account/following" class="nav-link" v-bind:class="{active: (activeTab == 'following') }">Pooles que sigo</router-link>
                            </li>
                            <!-- <li class="nav-item">
                                <router-link to="/account/notifications" class="nav-link" v-bind:class="{active: (activeTab == 'notifications') }">Notificaciones</router-link>
                            </li> -->
                        </ul>
                    </aside>
                </div>
                <div class="col-lg-9">
                    <div class="tab-pane tab-pane-navigation" id="MisDatos" v-bind:class="{active: (activeTab == 'user') }">
                        <h2 class="font-weight-bold line-height-3 text-7 mb-0">
                            Mis datos
                        </h2>
                        <AppMyAccountUserData/>
                    </div>
                    <div class="tab-pane tab-pane-navigation table-responsive" id="MisPooles" v-bind:class="{active: (activeTab == 'pools') }">
                        <h2 class="font-weight-bold line-height-3 text-7 mb-0">
                            Mis Pooles
                        </h2>
                        <AppMyAccountPools />
                    </div>
                    <div class="tab-pane tab-pane-navigation" id="PoolesQueSigo" v-bind:class="{active: (activeTab == 'following') }">
                        <h2 class="font-weight-bold line-height-3 text-7 mb-0">
                            Pooles que sigo
                        </h2>
                        <AppMyAccountFollowingPools />
                    </div>
                    <div class="tab-pane tab-pane-navigation" id="Notificaciones" v-bind:class="{active: (activeTab == 'notifications') }">
                        <h2 class="font-weight-bold line-height-3 text-7 mb-0">
                            Notificaciones
                        </h2>
                        <AppMyAccountNotifications />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import AppMyAccountUserData from '@/components/layout/account/AppMyAccountUserData.vue'
import AppMyAccountPools from '@/components/layout/account/AppMyAccountPools.vue'
import AppMyAccountFollowingPools from '@/components/layout/account/AppMyAccountFollowingPools.vue'
import AppMyAccountNotifications from '@/components/layout/account/AppMyAccountNotifications.vue'

export default {
  name: 'MyAccount',
  props: ['section'],
  components: {
    AppMyAccountUserData, AppMyAccountPools, AppMyAccountFollowingPools, AppMyAccountNotifications
  },
  data() {
    return {
        activeTab: 'user'
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.activeTab = to.params.section;
    next();
  },
  mounted() {
    const logged_in = this.$store.getters['auth/isLogged'];
    if(!logged_in) {
        this.$router.push({name: 'Home'});
    }
    this.activeTab = this.section;
  }
  
}
</script>