<template>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Ingresar"
      v-model="show"
      @hide="$emit('close')"
      hide-footer
      body-class="p-3"
    >
        <div slot="modal-title">
            <h4 class="modal-title text-capitalize" id="formModalLabel">Ingresar</h4>
        </div>
        <app-login />
    </b-modal>
</template>
<script>
    import AppLogin from './AppLogin';

    export default {
        name: 'AppLoginModal',
        components: { AppLogin },
        props: ['show']
    }
</script>