<template>
   <fixed-header :threshold="108">
       <div class="header-nav-bar bg-girsoscuro">
          <div class="header-container container">
             <div class="header-row">
                <div class="header-column">
                   <div class="header-row justify-content-end">
                      <div
                         class="header-nav header-nav-line header-nav-top-line header-nav-top-line-with-border justify-content-start"
                         data-sticky-header-style="{'minResolution': 991}"
                         data-sticky-header-style-active="{'margin-left': '105px'}"
                         data-sticky-header-style-deactive="{'margin-left': '0'}"
                         >
                         <div class="header-nav-main header-nav-main-arrows header-nav-main-effect-1 header-nav-main-sub-effect-1 header-nav-main-dropdown-no-borders w-100">
                            <nav class="collapse w-100">
                               <ul class="nav nav-pills nav-justified w-100" id="mainNav">
                                  <li class="dropdown dropdown-mega nav-item" v-for="(category, index) in categories.categories" :key="index">
                                    <router-link :to="'/list/category/' + category.id" class="dropdown-item dropdown-toggle">{{category.name}}</router-link>
                                        <ul class="dropdown-menu">
                                            <li>
                                               <div class="dropdown-mega-content">
                                                  <div class="row">
                                                     <div class="col-lg-6">
                                                        <ul class="list-unstyled card-columns">
                                                           <li v-for="(subcat, idx) in category.subcat" :key="idx">
                                                              <router-link :to="'/list/subcategory/' + subcat.id">{{subcat.name}}</router-link>
                                                           </li>
                                                        </ul>
                                                     </div>
                                                     <div class="col-lg-6 imgheader">
                                                        <h1>EXPLORÁ POOLES DE {{category.name}}</h1>
                                                        <p>Encontrá los mejores precios de la industria</p>
                                                        <router-link :to="'/list/category/' + category.id">
                                                            <button type="button" class="btn btn-secondary mb-2">
                                                            VER POOLES</button>
                                                        </router-link>
                                                     </div>
                                                  </div>
                                               </div>
                                            </li>
                                        </ul>
                                  </li>
                               </ul>
                            </nav>
                         </div>
                         <button class="btn header-btn-collapse-nav" data-toggle="collapse" data-target=".header-nav-main nav">
                         <i class="fas fa-bars"></i>
                         </button>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
   </fixed-header>
</template>

<script>
import FixedHeader from 'vue-fixed-header'
import { mapState } from 'vuex'

export default {
   name: 'AppHeaderNavbar',
   computed: mapState([
     'categories'
   ]),
   components: {FixedHeader},
   mounted() {
      this.$store.dispatch('categories/getAll');
   }
}
</script>

<style>
.header-nav-bar.vue-fixed-header--isFixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
}
</style> 