<template>
    <div class="row mb-5 pb-3 mt-1" id="thome">
        <div class="col-12">
            <h4 class="cazul text-4 mb-3"><i class="far fa-arrow-alt-circle-right mr-2"></i> Completa tus datos</h4>
        </div>
        <div class="col-12">
            <form autocomplete="off" role="form" class="needs-validation" v-on:submit.prevent="register()" novalidate>
                <div class="row">
                    <div class="form-group col-sm-6">
                        <label class="col-form-label form-control-label text-2 labelform required"> Nombre</label>
                        <div>
                            <input class="form-control text-3 h-50p py-2" type="text" name="name" id="first_name" placeholder="ingrese su nombre" required="" v-model="userData.first_name" v-bind:class="{error: errors.first_name}" />
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label class="col-form-label form-control-label text-2 labelform required"> Apellido</label>
                        <div>
                            <input class="form-control text-3 h-50p py-2" type="text" name="apellido"  id="last_name" placeholder="ingrese su apellido" required="" v-model="userData.last_name" v-bind:class="{error: errors.last_name}" />
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label class="col-form-label form-control-label text-2 labelform required"> Email</label>
                        <div>
                            <input class="form-control text-3 h-50p py-2" type="email" name="email" id="email" placeholder="ingrese su email" required="" v-model="userData.email" v-bind:class="{error: errors.email}" />
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label class="col-form-label form-control-label text-2 labelform required"> Teléfono</label>
                        <div>
                            <input class="form-control text-3 h-50p py-2" type="text" name="telefono" id="phone" placeholder="ingrese su teléfono" required="" v-model="userData.phone" v-bind:class="{error: errors.phone}" />
                        </div>
                    </div>
                    <div class="col-12">
                        <hr />
                    </div>
                    <div class="form-group col-sm-6">
                        <label class="col-form-label form-control-label text-2 labelform required">CUIT/CUIL</label>
                        <div>
                            <input id="cuit" class="form-control text-3 h-50p py-2" type="text" name="cuit" placeholder="ingrese el CUIT" v-model="userData.cuit" v-bind:class="{error: errors.cuit}" />
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label class="col-form-label form-control-label text-2 labelform"> Razón Social</label>
                        <div>
                            <input id="company_name" class="form-control text-3 h-50p py-2" type="text" name="codigopostal" placeholder="ingrese la razón social" v-model="userData.company_name" />
                        </div>
                    </div>
                    <div class="col-12">
                        <hr />
                    </div>
                    <div class="form-group col-sm-6">
                        <label class="col-form-label form-control-label text-2 labelform required"> Dirección</label>
                        <app-google-maps-autocomplete
                            id="address"
                            placeholder="ingrese la dirección"
                            v-bind:class="{error: errors.address}"
                            v-on:confirm="setAddress"
                        />
                    </div>
                    <div class="form-group col-sm-6">
                        <label class="col-form-label form-control-label text-2 labelform"> Piso/Departamento</label>
                        <div>
                            <input id="appartment" class="form-control text-3 h-50p py-2" v-model="userData.appartment" />
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <label class="col-form-label form-control-label text-2 labelform"> Comentarios adicionales</label>
                        <div>
                            <input id="comments" placeholder="Ej: KM 25" class="form-control text-3 h-50p py-2" v-model="userData.address_comments" />
                        </div>
                    </div>
                    <div class="col-12">
                        <hr />
                    </div>
                    <!-- SHIPPING ADDRESS -->
                    <div class="form-group col-sm-12 mb-0">
                        <div class="custom-checkbox-1 collapsed">
                            <input id="shippingAddress" type="checkbox" name="shippingAddress" v-model="userData.differentShippingAddress"/>
                            <label for="shippingAddress">¿La dirección de entrega es distinta?</label>
                        </div>
                    </div>
                    <div class="shipping-field-wrapper collapse col-12" v-bind:class="{show: userData.differentShippingAddress}">
                        <div class="row">
                            <div class="form-group col-sm-6">
                                <label class="col-form-label form-control-label text-2 labelform required"> Dirección Entrega</label>
                                <app-google-maps-autocomplete
                                    id="shipping_address"
                                    placeholder="ingrese la dirección de entrega"
                                    v-bind:class="{error: errors.shipping_address}"
                                    v-on:confirm="setShippingAddress"
                                />
                            </div>
                            <div class="form-group col-sm-6">
                                <label class="col-form-label form-control-label text-2 labelform"> Piso/Departamento</label>
                                <div>
                                    <input id="shipping_appartment" class="form-control text-3 h-50p py-2" v-model="userData.shipping_appartment" />
                                </div>
                            </div>
                            <div class="form-group col-sm-12">
                                <label class="col-form-label form-control-label text-2 labelform"> Comentarios adicionales</label>
                                <div>
                                    <input id="shipping_comments" placeholder="Ej: KM 25" class="form-control text-3 h-50p py-2" v-model="userData.shipping_address_comments" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <hr />
                    </div>
                    <div class="form-group col-sm-6">
                        <label class="col-form-label form-control-label text-2 labelform required"> Contraseña</label>
                        <div>
                            <input id="password" class="form-control text-3 h-50p py-2" type="password" name="password" value="" required="" v-bind:class="{error: errors.password}" v-model="userData.password" />
                        </div>
                    </div>
                    <div class="form-group col-sm-6">
                        <label class="col-form-label form-control-label text-2 labelform required"> Confirmar contraseña</label>
                        <div>
                            <input id="confirm_password" class="form-control text-3 h-50p py-2" type="password" name="confirmPassword" value="" required="" v-bind:class="{error: errors.confirm_password}" v-model="userData.confirm_password" />
                        </div>
                    </div>
                    <div class="col-12">
                        <hr />
                    </div>
                </div>
                <div class="form-group row">
                    <div class="form-group col-lg-8">
                        <div class="text-danger" v-if="error_message != ''">{{ error_message }}</div>
                    </div>
                    <div class="form-group col-lg-4 text-right">
                        <button v-on:click.prevent.stop="goBack" class="mb-1 mt-1 mr-1 btn btn-light">
                            <i class="fas fa-arrow-left"></i> Volver
                        </button>
                        <button type="submit" v-if="!loading" class="mb-1 mt-1 mr-1 btn btn-naranja">
                            Siguiente <i class="fas fa-arrow-right ml-1"></i>
                        </button>
                        <button type="button" v-else class="mb-1 mt-1 mr-1 btn btn-naranja">
                            Espere un momento...<i class="fas fa-arrow-right ml-1"></i>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import AppGoogleMapsAutocomplete from '@/components/layout/common/AppGoogleMapsAutocomplete';

    export default {
      name: 'AppRegisterSecondStep',
      components: { AppGoogleMapsAutocomplete },
      data() {
        return {
            loading: false,
            isCommerce: false,
            userData: {
                differentShippingAddress: false,
                address: {},
                shipping_address: {}
            },
            errors: {},
            error_message: '',
            password_policies: {
                min: 6
            },
            error_msgs: {
                different_passwords: 'Las contraseñas no coinciden.',
                invalid_password: 'Contraseña inválida.',
                invalid_email: 'Email inválido.',
                email_inuse: 'Esa direccion de correo electrónico ya está en uso.',
                api_error: 'Hubo un error. Por favor intente luego.'
            },
            required_fields: ['first_name', 'last_name', 'email', 'phone', 'address', 'password', 'confirm_password']
        }
      },
      methods: {
        setAddress(address) {
            this.userData.address = address;
        },
        setShippingAddress(address) {
            this.userData.shipping_address = address;
        },
        cleanErrors() {
            this.loading = true;
            this.error_message = '';
            this.errors = {};
        },
        register() {
            this.cleanErrors();

            if(!this.validateData()) {
                this.loading = false;
                return false;
            }

            this.$store.dispatch('user/register', this.userData)
                .then(
                   () => {
                        this.$store.commit('user/registrationNextStep');
                })
                .catch(error => {
                    this.loading = false;
                    if(error.error_code == 'USER_REGISTRATION_EMAIL_INUSE') {
                        this.errors.email = true;
                        this.error_message = this.error_msgs.email_inuse;
                        return false;
                    }
                    this.error_message = this.error_msgs.api_error;                
                });                
        },
        validateData() {
            if(this.userData.differentShippingAddress) {
                this.required_fields.push('shipping_address');
            }
            this.required_fields.push('cuit');
            // check if required fields are filled
            for(let field of this.required_fields) {
                if(!this.userData[field] || this.userData[field] == '') {
                    this.errors[field] = true;
                    document.getElementById(field).focus();
                    return false;
                }
            }

            // Validate if email is valid
            if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.userData.email)) {
               this.errors.email = true;
               this.error_message = this.error_msgs.invalid_email;
               return false;
            }
   
            // Validate is password is valid
            if(!this.validatePassword()) {
                this.errors.password = true;
                this.errors.confirm_password = true;
                return false;
            }

            // check address
            if(Object.keys(this.userData.address).length === 0) {
                this.errors.address = true;
                return false;
            }

            if(this.userData.differentShippingAddress && Object.keys(this.userData.shipping_address).length === 0) {
                this.errors.shipping_address = true;
                return false;
            }

            return true;
        },
        validatePassword() {
            // check password policies
            if(this.userData.password.length < this.password_policies.min) {
               this.error_message = this.error_msgs.invalid_password;
               this.loading = false;
               return false;
            }

            // check if passwords are equal
            if(this.userData.password !== this.userData.confirm_password) {
                this.error_message = this.error_msgs.different_passwords;
                return false;
            }
            return true;
        },
        goBack() {
            this.$store.commit('user/registrationPreviousStep');
        }
      }
    }
</script>