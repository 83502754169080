<template>
  <div>
    <div class="row">
      <div class="col-sm-3 col-6">
        <div class="cajaestados es1">
          <b>{{ price() }}</b>
          <br />precio final <i class="fas fa-dollar-sign"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es2">
          <b>{{ discount() }}</b><br />dto.
          final<i class="fas fa-percent"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es3">
          <b>{{ pool.buyers }}</b
          ><br />compradores <i class="fas fa-user-friends"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es3">
          <b>{{ priceUnit() }} {{ $helpers.formatNumber(pool.product.precio_mercado) }}</b
          ><br />precio mercado <i class="fas fa-chart-line"></i>
        </div>
      </div>
    </div>
    <hr />
    <!-- <div class="row">
      <div class="col-4 text-center ptarget" title="">
        <b>{{ $helpers.formatNumber(pool.volume) }} {{ unit }}</b>
        <br />Volumen total
      </div>
      <div
        class="col-4 text-center pmercado"
        data-toggle="tooltip"
        data-placement="top"
        title=""
        data-original-title="información"
      >
        <b>{{
            $helpers.formatNumber(pool.product.masa_critica_1 - pool.volume)
          }}
          {{ unit }}</b>
        <br />Volumen restante
      </div>
      <div
        class="col-4 text-center pepooler"
        data-toggle="tooltip"
        data-placement="top"
        title=""
        data-original-title="información"
      >
        <b>{{ $helpers.formatNumber(pool.product.masa_critica_1) }}
          {{ unit }}</b>
        <br />Volumen a alcanzar
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "AppPoolDataPanelFinished",
    methods: {
        price() {
           return this.pool.last_price ? this.priceUnit() + ' ' + this.pool.last_price.price : '-';
        },
        priceUnit() {
          return this.$helpers.getPriceUnit(this.pool);
        },
        discount() {
            if(this.pool.last_price) {
                const disc = (1 - this.pool.last_price.price / this.pool.product.precio_mercado) * 100;
                return Math.ceil(disc) + '%';
            }
            return '-';
        }
    },
    computed: mapState({
        pool: (state) => state.pool.data,
        unit: (state) => state.pool.data.product.unidad_medida,
    })
};
</script>