<template>
  <div>
    <div class="row">
      <div class="col-sm-3 col-6">
        <div class="cajaestados es1">
          <b>{{ priceUnit() }} {{ $helpers.formatNumber($helpers.getPotentialPrice(pool)) }}</b>
          <br />precio potencial <i class="fas fa-dollar-sign"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es2">
          <b>{{ Math.ceil(pool.product.descuento_1) }}%</b><br />dto.
          potencial<i class="fas fa-percent"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es3">
          <b>{{ pool.buyers }}</b
          ><br />compradores <i class="fas fa-user-friends"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es3">
          <b>{{ priceUnit() }} {{ $helpers.formatNumber(pool.product.precio_mercado) }}</b
          ><br />precio mercado <i class="fas fa-chart-line"></i>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-4 text-center ptarget" title="">
        <b>{{ $helpers.formatNumber(pool.volume) }} {{ unit }}</b>
        <br />Volumen actual
      </div>
      <div
        class="col-4 text-center pmercado"
        data-toggle="tooltip"
        data-placement="top"
        title=""
        data-original-title="información"
      >
        <b>{{
            $helpers.formatNumber(pool.product.masa_critica_1 - pool.volume)
          }}
          {{ unit }}</b>
        <br />Volumen restante
      </div>
      <div
        class="col-4 text-center pepooler"
        data-toggle="tooltip"
        data-placement="top"
        title=""
        data-original-title="información"
      >
        <b>{{ $helpers.formatNumber(pool.product.masa_critica_1) }}
          {{ unit }}</b>
        <br />Volumen a alcanzar
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "AppPoolDataPanelInitial",
    computed: mapState({
        pool: (state) => state.pool.data,
        unit: (state) => state.pool.data.product.unidad_medida,
    }),
    methods: {
      priceUnit() {
        return this.$helpers.getPriceUnit(this.pool);
      },
    }
};
</script>