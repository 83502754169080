<template>
    <b-modal
        id="modal-prevent-closing"
        ref="modal"
        title="Confirmar Oferta"
        v-model="show"
        @hide="$emit('close')"
        size="lg"
        >
        <div slot="modal-title">
            <h4 class="modal-title text-capitalize" id="formModalLabel">Confirmar Oferta</h4>
        </div>
        <table class="table">
            <tbody>
                <tr class="text-center">
                    <th class="product-col" colspan="2"></th>
                    <th class="price-col">Precio</th>
                    <th class="price-col">Precio Distribucion</th>
                    <th class="price-col">Precio Total</th>
                    <th class="qty-col">Volumen</th>
                    <th class="total-col">Total</th>
                </tr>
                <tr>
                    <td>
                        <img class="img-fluid maxw-64" :src="$helpers.apiAsset(pool.product.imagen_destacada)" alt="Project Image" />
                    </td>
                    <td class="tdnombre">
                        {{ pool.product.nombre_comercial }}
                    </td>
                    <td class="tdprecio">
                        ${{ $helpers.formatNumber(newBid.price) }}
                        <span class="cgris"> por {{ $helpers.formatUnit(pool.product.unidad_medida) }}</span>
                    </td>
                    <td class="tdprecio">
                        ${{ $helpers.formatNumber(newBid.shipping_price) }}
                        <span class="cgris"> por {{ $helpers.formatUnit(pool.product.unidad_medida) }}</span>
                    </td>
                    <td class="tdprecio">
                        ${{ $helpers.formatNumber(newBid.price + newBid.shipping_price) }}
                        <span class="cgris"> por {{ $helpers.formatUnit(pool.product.unidad_medida) }}</span>
                    </td>
                    <td class="tdprecio">
                        {{ $helpers.formatNumber(pool.volume) }}
                        <span class="cgris"> {{ $helpers.formatUnit(pool.product.unidad_medida) }}</span>
                    </td>
                    <td class="tdcomprdores">
                        $ {{ $helpers.formatNumber(pool.volume * (newBid.price + newBid.shipping_price)) }}
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- <div class="pl-3">
            @TODO cargar comentario 
            <p>* Aca irian todo tipo de aclaraciones.</p>
        </div> -->
        <template #modal-footer>
            <div v-if="error_message != ''" class="text-danger font-weight-bold">
                {{ error_message }}
            </div>
            <button v-if="loading" class="btn btn-naranja btn-modern text-uppercase float-right" disabled>Espere un momento...</button>
            <button v-else class="btn btn-naranja btn-modern text-uppercase float-right" v-on:click.stop.prevent="makeBid()">Confirmar Oferta</button>
        </template>
    </b-modal>
</template>
<script>
    import { mapState } from "vuex";

    export default {
        name: 'AppPoolBidModal',
        props: ['show'],
        data() {
            return {
                loading: false,
                error_message: '',
                errors: {
                    invalid_amount: 'El precio total no puede ser mayor al de la ultima oferta.'
                }
            }
        },
        computed: mapState({
            newBid: (state) => state.pool.new_bid,
            pool: (state) => state.pool.data
        }),
        methods: {
            makeBid() {
                this.$store.dispatch('pool/makeBid', {
                    pool_uuid: this.pool.uuid,
                    price: this.newBid.price,
                    shipping_price: this.newBid.shipping_price
                })
                .then(() => {
                        this.error_message = '';
                        this.loading = false;
                        this.$emit('close');
                        this.$refs["modal"].hide();
                    },
                    error => {
                        this.loading = false;
                        if(error.error_code == 'POOL_BID_INVALID_AMOUNT') {
                            this.error_message = this.errors.invalid_amount;
                        }
                    }
                );
                
            }
        }
    }
</script>
