<template>
  <div>
    <div role="main" class="main">
      <AppHomeSlider></AppHomeSlider>

      <div class="container">
        <div class="row mb-5" id="thome">
          <div
            class="
              col-md-6 col-lg-4
              appear-animation
              animated
              fadeInUpShorter
              appear-animation-visible
              mb-2
            "
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="600"
            style="animation-delay: 600ms"
          >
            <div
              class="
                card
                bg-color-white
                card-text-color-hover-light
                bg-color-hover-cyan
                transition-2ms
                box-shadow-1 box-shadow-1-cyan box-shadow-1-hover
                card-border-cyan
              "
            >
              <div class="card-body pb-0">
                <h4
                  class="
                    card-title
                    mb-1
                    text-5
                    font-weight-bold
                    transition-2ms
                    ccyan
                  "
                >
                  Comprá
                </h4>
                <p class="card-text transition-2ms">
                  Entrega a domicilio en menos de 1 semana. Comprá tus insumos
                  al mejor precio.<br /><b class="ccyan">Ahorrá hasta un 50%</b>
                </p>
                <i class="fas fa-shopping-cart"></i>
              </div>
            </div>
          </div>
          <div
            class="
              col-md-6 col-lg-4
              appear-animation
              animated
              fadeInUpShorter
              appear-animation-visible
              mb-2
            "
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="600"
            style="animation-delay: 600ms"
          >
            <div
              class="
                card
                bg-color-white
                card-text-color-hover-light
                bg-color-hover-naranja
                transition-2ms
                box-shadow-1 box-shadow-1-naranja box-shadow-1-hover
                card-border-naranja
              "
            >
              <div class="card-body pb-0">
                <h4
                  class="
                    card-title
                    mb-1
                    text-5
                    font-weight-bold
                    transition-2ms
                    cnaranja
                  "
                >
                  Vendé
                </h4>
                <p class="card-text transition-2ms">
                  Unite a la comunidad epooler y sumá beneficios. Vendé más sin
                  moverte de tu negocio.<br /><b class="cnaranja"
                    >Aumentá tus venta un 50%</b
                  >
                </p>
                <i class="fas fa-dollar-sign"></i>
              </div>
            </div>
          </div>
          <div
            class="
              col-md-6 col-lg-4
              appear-animation
              animated
              fadeInUpShorter
              appear-animation-visible
              mb-2
            "
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="600"
            style="animation-delay: 600ms"
          >
            <div
              class="
                card
                bg-color-white
                card-text-color-hover-light
                bg-color-hover-amarillo
                transition-2ms
                box-shadow-1 box-shadow-1-amarillo box-shadow-1-hover
                card-border-amarillo
              "
            >
              <div class="card-body pb-0">
                <h4
                  class="
                    card-title
                    mb-1
                    text-5
                    font-weight-bold
                    transition-2ms
                    camarillo
                  "
                >
                  Distribuí
                </h4>
                <p class="card-text transition-2ms">
                  Te conectamos a cadena de compras ya creadas. Ofrecé tus
                  servicios y empezá a distribuir.<br /><b class="camarillo"
                    >Distribuí en todo el país</b
                  >
                </p>
                <i class="fas fa-truck"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- featured products list -->
    <app-home-product-list
        background="bgpoolhome"
    />

    <!-- in process list -->
    <app-home-pool-list
        type="in_process"
        background="bg-color-white"
    />

    <!-- completed list -->
    <app-home-pool-list
        type="completed"
        background="bg-color-white"
    />

    <!-- finished list -->
    <app-home-pool-list-finished
        background="bg-color-white"
    />

    <section class="section section-default border-0 my-2">
      <app-home-services />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import AppHomeSlider from "@/components/layout/home/AppHomeSlider.vue";
import AppHomeProductList from "@/components/layout/home/AppHomeProductList";
import AppHomePoolList from "@/components/layout/home/AppHomePoolList";
import AppHomePoolListFinished from "@/components/layout/home/AppHomePoolListFinished";
import AppHomeServices from "@/components/layout/home/AppHomeServices";
import { mapState } from "vuex";

export default {
    name: "Home",
    components: {
        AppHomeSlider,
        AppHomeProductList,
        AppHomePoolList,
        AppHomePoolListFinished,
        AppHomeServices,
    },
    computed: mapState({
        loggedIn: (state) => state.auth.status.loggedIn,
        userType: (state) => {
            return (state.auth.user) ? state.auth.user.user_data.type : 0
        }
    })
};
</script>
