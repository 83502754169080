<template>
    <div class="row mb-5 pb-3 mt-1" id="thome">
        <div class="col-12">
            <h4 class="cazul text-4 mb-5"><i class="far fa-arrow-alt-circle-right mr-2"></i> Seleccioná que tipo de usuario sos</h4>
        </div>
        <div v-on:click="nextStep(1)" role="button" class="col-md-6 col-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible mb-2" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" style="animation-delay: 600ms;">
            <div class="card bg-color-white card-text-color-hover-light bg-color-hover-cyan transition-2ms box-shadow-1 box-shadow-1-cyan box-shadow-1-hover card-border-cyan">
                <div class="card-body pb-0">
                    <h4 class="card-title mb-1 text-5 font-weight-bold transition-2ms ccyan">Comprador</h4>
                    <ul class="list list-icons list-icons-style-2 list-icons-sm pb-2 pt-3">
                        <li class="text-1"><i class="fas fa-check text-color-light border-cyan"></i> Comprá tus insumos al mejor precio</li>
                        <li class="text-1"><i class="fas fa-check text-color-light border-cyan"></i> Ahorrá hasta un 50%</li>
                        <li class="text-1"><i class="fas fa-check text-color-light border-cyan"></i> Entrega a domicilio en menos de 1 semana</li>
                    </ul>
                    <a href="#" class="scategoria text-color-light d-block text-right cursor-pointer">
                        Seleccionar <span>></span>
                    </a>
                    <i class="fas fa-shopping-cart l-15"></i>
                </div>
            </div>
        </div>
        <div v-on:click="nextStep(2)" role="button" class="col-md-6 col-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible mb-2" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" style="animation-delay: 600ms;">
            <div class="card bg-color-white card-text-color-hover-light bg-color-hover-naranja transition-2ms box-shadow-1 box-shadow-1-naranja box-shadow-1-hover card-border-naranja">
                <div class="card-body pb-0">
                    <h4 class="card-title mb-1 text-5 font-weight-bold transition-2ms cnaranja">Vendedor</h4>
                    <ul class="list list-icons list-icons-style-2 list-icons-sm pb-2 pt-3">
                        <li class="text-1"><i class="fas fa-check text-color-light border-naranja"></i> Vendé más sin moverte de tu negocio</li>
                        <li class="text-1"><i class="fas fa-check text-color-light border-naranja"></i> Aumentá tus ventas hasta un 50%</li>
                        <li class="text-1"><i class="fas fa-check text-color-light border-naranja"></i> Unite a epooler y sumá beneficios</li>
                    </ul>
                    <a href="#" class="scategoria text-color-light d-block text-right cursor-pointer">
                        Seleccionar <span>></span>
                    </a>
                    <i class="fas fa-dollar-sign l-15"></i>
                </div>
            </div>
        </div>
        <div v-on:click="nextStep(1)" role="button" class="col-md-6 col-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible mb-2" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" style="animation-delay: 600ms;">
            <div class="card bg-color-white card-text-color-hover-light bg-color-hover-amarillo transition-2ms box-shadow-1 box-shadow-1-amarillo box-shadow-1-hover card-border-amarillo">
                <div class="card-body pb-0">
                    <h4 class="card-title mb-1 text-5 font-weight-bold transition-2ms camarillo">Transportista</h4>
                    <ul class="list list-icons list-icons-style-2 list-icons-sm pb-2 pt-3">
                        <li class="text-1"><i class="fas fa-check text-color-light border-amarillo"></i> Conecta cadenas de compras ya creadas</li>
                        <li class="text-1"><i class="fas fa-check text-color-light border-amarillo"></i> Ofrecé tus servicios y empezá a distribuir</li>
                        <li class="text-1"><i class="fas fa-check text-color-light border-amarillo"></i> Distribuí en todo el país</li>
                    </ul>
                    <a href="#" class="scategoria text-color-light d-block text-right cursor-pointer">
                        Seleccionar <span>></span>
                    </a>
                    <i class="fas fa-truck l-15"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'AppRegisterFirstStep',
        methods: {
            nextStep(user_type) {
                this.$store.commit('user/goToSecondRegistrationStep', user_type);
            }
        }
    }
</script>
