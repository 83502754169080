<template>
    <div>
        <div class="card card-body bg-color-light mb-4">
            <h4>
                <i class="far fa-bell"> </i>
                Listado de notificaciones
            </h4>
            <div class="alert alert-warning alert-dismissible" role="alert">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true"> ×</span>
                </button>
                <strong>
                <i class="far fa-check-square"> </i>
                </strong>
                Esta es una notificación de aviso.
            </div>
            <div class="alert alert-danger alert-dismissible" role="alert">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true"> ×</span>
                </button>
                <strong>
                <i class="far fa-check-square"> </i>
                </strong>
                Esta es una notificación de alerta.
            </div>
            <div class="alert alert-info alert-dismissible" role="alert">
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true"> ×</span>
                </button>
                <strong>
                <i class="far fa-check-square"> </i>
                </strong>
                Esta es una notificación.
            </div>
        </div>
        <div class="card card-body bg-color-light mb-4">
            <h4>
                <i class="far fa-edit"> </i>
                Editá tus notificaciones
            </h4>
            <p class="mb-0 pb-2">
                Elegí en dónde querés que te lleguen las notificaciones de los pooles. Te enviaremos una notificación cada vez que suceda algo interesante.
            </p>
            <hr />
            <form>
                <div class="row mb-2">
                    <div class="form-group col-sm-12 mb-0">
                        <h5 class="mb-1">
                            Metodo de notificación
                        </h5>
                    </div>
                    <div class="form-group col-sm-12">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="smspool" value="smspool" />
                            <label class="form-check-label" for="smspool"> SMS</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="emailpool" value="emailpool" />
                            <label class="form-check-label" for="emailpool"> EMAIL</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="webpool" value="webpool" />
                            <label class="form-check-label" for="webpool"> WEP</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="apppool" value="apppool" />
                            <label class="form-check-label" for="apppool"> APP</label>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="form-group col-sm-12 mb-0">
                        <h5 class="mb-1">
                            Tipo de notificación
                        </h5>
                    </div>
                    <div class="form-group col-sm-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="NotificacionesPool" />
                            <label class="custom-control-label cazul" for="NotificacionesPool">Notificaciones de Pool</label>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="NotificacionesVenta" />
                            <label class="custom-control-label cazul" for="NotificacionesVenta">Notificaciones de Venta</label>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="NotificacionesCompra" />
                            <label class="custom-control-label cazul" for="NotificacionesCompra">Notificaciones de Compra</label>
                        </div>
                    </div>
                    <div class="form-group col-sm-12">
                        <input type="submit" value="Guardar" class="btn btn-naranja btn-modern float-right" data-loading-text="Loading..." />
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    export default {
      name: 'AppMyAccountNotifications'
     }
</script>