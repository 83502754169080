<template>
  <section
    class="section section-default border-0 my-0 shop"
    v-bind:class="background"
    v-if="pools.length > 0"
  >
    <div class="container" v-if="pools.length > 0">
      <div class="row">
        <div class="col">
          <h4 class="cazul text-7 mb-4">
            <i class="far fa-clock mr-2"></i> Pools finalizados
          </h4>
          <carousel
            v-if="data_loaded"
            class="
              owl-carousel owl-theme
              show-nav-hover
              owl-loaded owl-drag owl-carousel-init
            "
            :autoplay="false"
            :navText="['<', '>']"
            :margin="20"
            :loop="false"
            :nav="true"
            :dots="false"
            :responsive="{
              0: { items: 1 },
              479: { items: 1 },
              768: { items: 1 },
              979: { items: 4 },
              1199: { items: 4 },
            }"
          >
            <app-pool-finished
              v-for="(pool, index) in pools"
              :key="index"
              :pool="pool"
            />
          </carousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AppPoolFinished from "@/components/layout/common/AppPoolFinished";
import carousel from "vue-owl-carousel";
import PoolService from "@/services/pool-service";

export default {
  name: "AppHomePoolListFinished",
  props: ["background"],
  components: { AppPoolFinished, carousel },
  data() {
    return {
      pools: [],
      data_loaded: false,
    };
  },
  mounted() {
    const logged_in = this.$store.getters["auth/isLogged"];
    PoolService.list("finished", logged_in).then((response) => {
      this.pools = response.data.data;
      this.data_loaded = true;
    });
  },
};
</script>