<template>
  <div
    class="
      header-nav-features
      header-nav-features-no-border
      header-nav-features-lg-show-border
      col-lg-2
      order-1 order-lg-2
    "
  >
    <!-- IF NOT LOGGED IN -->
    <div
      class="
        header-nav-feature header-nav-features-user
        d-inline-flex
        mx-2
        pr-2
        signin
      "
      id="headerAccount"
      v-if="!loggedIn"
    >
      <a
        href="#"
        class="header-nav-features-toggle btn-ingresar"
        v-on:click="toggleDropdown"
      >
        <i class="fas fa-sign-in-alt"></i>
        Ingresar
      </a>
      <div
        class="
          header-nav-features-dropdown
          header-nav-features-dropdown-mobile-fixed
          header-nav-features-dropdown-force-right
        "
        id="headerTopUserDropdown"
      >
        <AppLogin showtitle="true" />
      </div>
    </div>
    <!-- IF IT'S LOGGED IN -->
    <div
      class="
        header-nav-feature
        header-nav-features-user
        header-nav-features-user-logged
        d-inline-flex
        mx-2
        pr-1
      "
      v-if="loggedIn"
    >
      <a
        href="#"
        class="header-nav-features-toggle"
        v-on:click="toggleDropdown"
      >
        <i class="far fa-user"></i>{{ getUserProfileName }}
      </a>
      <div
        class="
          header-nav-features-dropdown
          header-nav-features-dropdown-mobile-fixed
          header-nav-features-dropdown-force-right
        "
      >
        <div class="row">
          <div class="col-8">
            <p class="mb-0 pb-0 text-2 line-height-1 pt-1">Hola,</p>
            <p>
              <strong class="text-color-dark text-4">{{
                getUserProfileName
              }}</strong>
            </p>
          </div>
          <div class="col-4">
            <div class="d-flex justify-content-end">
              <div
                style="
                  padding-top: 9px;
                  text-align: center;
                  display: block;
                  border-radius: 25px;
                  height: 50px;
                  width: 50px;
                "
                v-bind:class="{ cyan: userType == 1, red: userType == 2 }"
              >
                <i
                  style="color: #eee"
                  class="fa-2x rounded-circle fas fa-user"
                  width="40"
                  height="40"
                ></i>
              </div>
              <!-- <img class="rounded-circle" width="40" height="40" alt="" src="@/assets/img/avatars/avatar.jpg" /> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <ul class="nav nav-list-simple flex-column text-3">
              <li class="nav-item">
                <router-link to="/account/user" class="nav-link"
                  >Mi Perfil</router-link
                >
              </li>
              <li class="nav-item">
                <router-link to="/account/pools" class="nav-link"
                  >Mis Pooles</router-link
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link border-bottom-0"
                  href="javascript:void(0)"
                  v-on:click.stop="handleLogout"
                  >Salir</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <AppHeaderNotifications v-if="loggedIn" />
  </div>
</template>
<script>
import Utils from "@/services/layout/utils";
import AppHeaderNotifications from "./AppHeaderNotifications";
import AppLogin from "./AppLogin";
import { mapState } from "vuex";

export default {
  name: "AppHeaderLogin",
  components: { AppHeaderNotifications, AppLogin },
  computed: mapState({
    pool: (state) => state.pool.data,
    loggedIn: (state) => state.auth.status.loggedIn,
    userType: (state) => {
      return state.auth.user ? state.auth.user.user_data.type : 0;
    },
    getUserProfileName() {
      let name = this.$store.state.auth.user.user_data.name;
      name = name.split(" ")[0];
      return name ?? "Usuario";
    },
  }),
  methods: {
    userTypeColor() {
      const colors = ["", "cyan", "red", "yellow"];
      return colors[this.userType];
    },
    toggleDropdown: Utils.toggleDropdown,
    handleLogout() {
      this.$store.dispatch("auth/logout", this.user).then(() => {
        location.href = "/";
      });
    },
  },
};
</script>

<style scoped>
.cyan {
  background-color: #00aac2;
}
.red {
  background-color: #e4522c;
}

.yellow {
  background-color: #ef9a00;
}
</style>