<template>
   <div>
      <div class="signin-form" v-if="!showRecover">
         <h5 v-if="showtitle" class="text-uppercase mb-4 font-weight-bold text-3">Ingresar</h5>
         <form @submit.prevent="handleLogin" novalidate>
            <div class="form-group">
               <label class="mb-1 text-2 opacity-8">Email* </label>
               <input type="email" class="form-control form-control-lg" v-bind:class="{error: email_error }" v-model="user.email" />
            </div>
            <div class="form-group">
               <label class="mb-1 text-2 opacity-8">Contraseña *</label>
               <input type="password" class="form-control form-control-lg" v-bind:class="{error: password_error }" v-model="user.password" />
            </div>
            <div class="form-row pb-2">
               <div class="form-group form-check col-lg-6 pl-1">
                  <div class="custom-control custom-checkbox">
                     <input type="checkbox" class="custom-control-input" id="rememberMeCheck" />
                     <label class="custom-control-label text-2" for="rememberMeCheck">Recordarme</label>
                  </div>
               </div>
               <div class="form-group col-lg-6 text-right">
                  <a href="javascript:void(0);" class="text-uppercase text-1 font-weight-bold text-color-dark" id="headerRecover"  v-on:click.stop.prevent="recoverPassword">PERDIO SU CONTRASEÑA?</a>
               </div>
            </div>
            <div class="form-row login-error text-danger font-weight-bold" v-if="login_error != ''">{{login_error}}</div>
            <div class="actions">
               <div class="form-row">
                  <div class="col d-flex justify-content-end">
                     <button class="btn btn-naranja" v-if="!loading">Ingresar</button>
                     <button class="btn btn-naranja" v-else disabled>Cargando...</button>
                  </div>
               </div>
            </div>
            <div class="extra-actions">
                <hr>
                <p>No tiene cuenta?
                    <a href="/register" class="text-uppercase text-1 font-weight-bold text-color-dark">Regístrese</a>
                </p>
            </div>
         </form>
      </div>
      <div class="recover-container" v-if="showRecover">
         <h5 class="text-uppercase mb-2 pb-1 font-weight-bold text-3">Resetear Contraseña</h5>
         <p class="text-2 mb-4">Complete el formulario a para recibir un correo electrónico con el código de autorización necesario para restablecer su contraseña.</p>
         <form>
            <div class="form-group">
               <label class="mb-1 text-2 opacity-8">Email* </label>
               <input type="email" class="form-control form-control-lg" />
            </div>
            <div class="actions">
               <div class="form-row">
                  <div class="col d-flex justify-content-end">
                     <a class="btn btn-naranja" href="#">Resetear</a>
                  </div>
               </div>
            </div>
            <div class="extra-actions">
               <hr>
               <p>Ya tiene cuenta? <a href="#" id="headerRecoverCancel" class="text-uppercase text-1 font-weight-bold text-color-dark" v-on:click.stop.prevent="recoverCancel">Ingresar</a></p>
            </div>
         </form>
      </div>
   </div>
</template>

<script>
   import $ from 'jquery';
   import AuthService from '@/services/auth-service';
   
   export default {
       name: 'AppLogin',
       data() {
           return {
               showRecover: false,
               loading: false,
               password_policies: {
                   min: 6
               },
               login_error: '',
               email_error: false,
               password_error: false,
               user: {
                   email: '',
                   password: '',
               },
               login_errors: {
                   empty_form: 'Por favor ingrese email y contraseña.',
                   invalid_email: 'Email inválido',
                   unverified_email: 'Debe verificar su email. Por favor revise su correo.',
                   invalid_password: 'Password inválido',
                   invalid_credentials: 'Datos incorrectos.',
                   login_api_error: 'Hubo un error al iniciar sesión'
               }
           }
       },
       props: ['showtitle'],
       computed: {
            loggedIn() {
               return this.$store.state.auth.status.loggedIn;
            },
       },
       methods: {
           handleLogout() {
               this.$store.dispatch('auth/logout', this.user).then(
                   () => {
                       location.href = '/';
                   }
               );
           },
           handleLogin() {
               this.cleanErrors;
               this.loading = true;
   
               // Validate if fields are empty
               if(!this.user.email && !this.user.password) {
                   this.login_error = this.login_errors.empty_form;
                   this.email_error = true;
                   this.password_error = true;
                   this.loading = false;
                   return false;
               }
   
               // Validate if email is valid
               if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.user.email)) {
                   this.email_error = true;
                   this.login_error = this.login_errors.invalid_email;
                   this.loading = false;
                   return false;
               }
   
               // Validate is password is valid
               if(this.user.password.length < this.password_policies.min) {
                   this.password_error = true;
                   this.login_error = this.login_errors.invalid_password;
                   this.loading = false;
                   return false;
               }
   
               this.$store.dispatch('auth/login', this.user).then(
                   () => {
                        if (this.$store.state.auth.user.user_data.type === 4) {
                            const adminUrl = AuthService.getAdminUrl();
                            window.location.href = adminUrl + '?access_token=' + this.$store.getters['auth/getUserToken'];
                            return false;
                        }
                        this.$router.go('');
                   },
                   error => {
                       this.loading = false;
                       switch(error.error_code) {
                            case 'LOGIN_INVALID_CREDENTIALS':
                                this.login_error = this.login_errors.invalid_credentials;
                                break;
                            case 'LOGIN_USER_UNVERIFIED':
                                this.login_error = this.login_errors.unverified_email;
                                break;
                            default:
                                this.login_error = this.login_errors.login_api_error;
                                break;
                       }               
                   }
               );
           },
           cleanErrors() {
               this.login_error = '';
               this.email_error = false;
               this.password_error = false;
           },
           recoverPassword: function(e) {
               e.preventDefault();
               this.showRecover = true;
               $('form').find('.recover-container input:first').focus();
               return false;
           },
           recoverCancel: function(e) {
               e.preventDefault();
               this.showRecover = false;
               $('form').find('.signin-form input:first').focus();
               return false;
           }
       }
   }
</script>