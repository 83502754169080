import { pool } from '../store/modules/pool-module';
import { httpClient } from './http-client';

class PoolService 
{
     search(product_id, location, token) {
        const uri = (!token) ? 'public/pool/search/' : 'pool/search/';
        let data = {
            product_id: product_id,
        };

        if(!token && location) {
            data.lat = location.lat;
            data.lon = location.lon;
        }
        
        return httpClient
            .post(uri, data)
            .then(response => {
                return response.data;
            });
    }

    getData(pool_uuid, is_logged = false) {
        if(!pool_uuid) {
            return false;
        }
        
        const uri = (is_logged) ? 'pool/get/' + pool_uuid : 'public/pool/get/' + pool_uuid;
        return httpClient
                .get(uri);
    }

    followPool(pool_uuid) {
        if(!pool_uuid) {
            return false;
        }
        const uri = 'pool/follow/';

        return httpClient
                .post(uri, {pool_uuid: pool_uuid});
    }

    list(criteria, is_logged = false) {
        if(!criteria) {
            return false;
        }
        
        let uri = (is_logged) ? 'pool/list/' : 'public/pool/list/';
        uri += '?criteria=' + criteria;
        
        return httpClient
                .get(uri);
    }

    makeReservation(pool_uuid, amount) {
        if(!pool_uuid) {
            return false;
        }

        let uri = 'pool/reservation/';
        
        return httpClient
                .post(uri, {
                    amount: amount,
                    pool_uuid: pool_uuid
                });
    }
    makeBid(pool_uuid, price, shipping_price) {
        if(!pool_uuid) {
            return false;
        }

        let uri = 'pool/bid/';
        
        return httpClient
                .post(uri, {
                        pool_uuid: pool_uuid,
                        amount: price,
                        shipping_amount: shipping_price
                    },
                    { showError: false }
                );
    }
    getReservations(pool_uuid) {
        if(!pool_uuid) {
            return false;
        }

        let uri = 'pool/reservations/' + pool_uuid;
        
        return httpClient
                .get(uri);
    }
}

export default new PoolService();