<template>
  <section
        class="section section-default border-0 my-0 shop"
        v-bind:class="background"
        v-if="list.length > 0"
  >
        <div class="container products" v-if="list.length > 0 && data_loaded">
            <div class="row">
            <div class="col">
                <h4 class="cazul text-7 mb-4">
                <i class="far fa-clock mr-2"></i>
                <span v-if="type == 'completed'">Pools completos</span>
                <span v-else-if="!logged_in || type == 'in_process'">Pools en proceso</span>
                </h4>
                <carousel
                v-if="data_loaded"
                class="
                    owl-carousel owl-theme
                    show-nav-hover
                    owl-loaded owl-drag owl-carousel-init
                "
                :autoplay="false"
                :navText="['<', '>']"
                :margin="20"
                :loop="false"
                :nav="true"
                :dots="false"
                :responsive="{
                    0: { items: 1 },
                    479: { items: 1 },
                    768: { items: 1 },
                    979: { items: 4 },
                    1199: { items: 4 },
                }"
                >
                <app-pool-in-process
                    v-for="(pool, index) in list"
                    :key="index"
                    :pool="pool"
                />
                </carousel>
            </div>
            </div>
        </div>
  </section>
</template>

<script>
import AppPoolInProcess from "@/components/layout/common/AppPoolInProcess";
import carousel from "vue-owl-carousel";

export default {
  name: "AppHomePoolList",
  props: ['type', 'background'],
  components: { AppPoolInProcess, carousel },
  data() {
    return {
        data_loaded: false,
        list: []
    };
  },
  mounted() {
    this.$store.dispatch('pool/getList', this.type)
        .then(() => {
            this.list = this.$store.getters['pool/getLists'][this.type];
            this.data_loaded = true;
        });
  }
};
</script>