<template>
    <div class="card card-body bg-color-light mb-4">
        <h4>
            <i class="far fa-star"> </i>
            Pooles que sigo
        </h4>
        <table class="table">
            <tbody>
                <tr v-for="(pool, index) in followed_pools" :key="index">
                    <td class="tdimagenc">
                        <span class="img-thumbnail d-block">
                        <img class="img-fluid maxw-64" :src="$helpers.apiAsset(pool.product.imagen_destacada)" alt="Project Image" />
                        </span>
                    </td>
                    <td class="tdnombre">
                        {{ pool.product.nombre_comercial }}
                    </td>
                    <td class="tdprecio">${{ $helpers.getPotentialPrice(pool) }} <span class="cgris"> Precio por {{ $helpers.formatUnit(pool.product.unidad_medida) }}</span></td>
                    <td class="tdcomprdores">{{ pool.buyers }} <span class="cgris"> Compradores</span></td>
                    <td class="tddias">
                        <app-pool-countdown v-if="!$helpers.isFinished(pool.finish_date)" :end="pool.finish_date" :isfinished="finished" />
                        <p v-else>Finalizado</p>
                        <!-- <span class="cgris"> Días restantes</span> -->
                    </td>
                    <td class="tdaccion">
                        <router-link v-if="!$helpers.isFinished(pool.finish_date)" :to="{ path: '/pool/' + pool.uuid }">
                            <i class="fas fa-search"></i>
                        </router-link>
                        <!-- <i class="fas fa-trash-alt"> </i> -->
                    </td>
                </tr>
                <tr v-if="followed_pools.length == 0">
                    <td>
                        <strong>No participas en ningún pool activo</strong>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="6"></td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>
<script>
    import AppPoolCountdown from '@/components/layout/pool/AppPoolCountdown';
    import UserService from '@/services/user-service';
        
    export default {
        name: 'AppMyAccountFollowingPools',
        components: { AppPoolCountdown },
        data() {
            return {
                followed_pools: []
            }
        },
        mounted() {
            UserService.getFollowedPools()
            .then(data => {
                this.followed_pools = data.data.pools;
            });
        }
    }
</script>