<template>
    <div class="card card-body bg-color-light mb-4">
        <form v-on:submit.prevent="updateProfile()">
            <div class="row">
                <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform required"> Nombre</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="text" name="nombre" placeholder="ingrese su nombre" v-model="new_data.name" required v-bind:class="{error: errors.name}"/>
                    </div>
                </div>
                <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform"> Nombre de la Empresa</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="text" name="nombre" placeholder="ingrese el nombre de la empresa" v-model="new_data.company_name" />
                    </div>
                </div>
                <!-- <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform required"> Apellido</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="text" name="apellido" placeholder="ingrese su apellido" data-msg-required="Este campo es requerido" required />
                    </div>
                </div> -->
                <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform required"> Email</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="email" name="email" placeholder="ingrese su email" v-model="new_data.email" v-bind:class="{error: errors.email}" required />
                    </div>
                </div>
                <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform required"> Teléfono</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="text" name="telefono" placeholder="ingrese su teléfono" v-model="new_data.phone" v-bind:class="{error: errors.phone}" required />
                    </div>
                </div>
                <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform required"> Dirección</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="text" name="direccion" placeholder="ingrese la dirección" v-model="new_data.address.formatted_address" disabled required />
                    </div>
                </div>
                <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform required"> Dirección Entrega</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="text" name="direccion" placeholder="ingrese la dirección" v-model="new_data.delivery_address.formatted_address" disabled required />
                    </div>
                </div>
                <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform required"> Provincia</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="text" name="provincia" placeholder="ingrese la provincia" v-model="new_data.address.province" disabled required />
                    </div>
                </div>
                <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform required"> Localidad</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="text" name="localidad" placeholder="ingrese la localidad" v-model="new_data.address.city" disabled />
                    </div>
                </div>
               <!--  <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform required"> Código Postal</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="text" name="codigopostal" placeholder="ingrese su código postal" data-msg-required="Este campo es requerido" required />
                    </div>
                </div> -->
                <div class="col-12">
                    <hr />
                </div>
                <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform required"> Contraseña</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="password" name="password" v-model="new_password" v-bind:class="{error: errors.password}" />
                    </div>
                </div>
                <div class="form-group col-sm-6">
                    <label class="col-form-label form-control-label text-2 labelform required"> Confirmar contraseña</label>
                    <div>
                        <input class="form-control text-3 h-50p py-2" type="password" name="confirmPassword" value="" v-model="new_password_confirm" v-bind:class="{error: errors.password}" />
                    </div>
                </div>
                <div class="col-12">
                    <hr />
                </div>
            </div>
            <div class="form-group row">
                <div class="form-group col-lg-9">
                    <div class="text-danger" v-if="error_message != ''">{{ error_message }}</div>
                    <div class="text-success" v-if="success">Datos actualizados correctamente.</div>
                </div>
                <div class="form-group col-lg-3">
                    <button class="btn btn-naranja btn-modern float-right" v-if="!loading">Guardar</button>
                    <button class="btn btn-naranja btn-modern float-right" v-else disabled>Cargando...</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import UserService from '@/services/user-service';
// import store from '@/store'
import { mapState } from 'vuex';

export default {
    name: 'AppMyAccountUserData',
    data() {
        return {
            loading: false,
            success: false,
            new_data: {
                address: {},
                delivery_address: {}
            },
            new_password: '********',
            new_password_confirm: '',
            errors: {},
            error_message: ''
        }
    },
    computed: mapState({  
        userData: state => state.user.data
    }),
    methods: {
        cleanErrors() {
            this.loading = true;
            this.success = false;
            this.error_message = '';
            this.errors = {};
        },
        updateProfile() {
            this.cleanErrors();

            if(!this.validateData()) {
                this.loading = false;
                return false;
            }

            UserService.updateProfile(this.new_data)
                .then(() => {
                    this.loading = false;
                    this.success = true;
                })
                .catch(this.handleErrors);
        },
        validateData() {
            return true;
        },
        handleErrors(error) {
            this.loading = false;
            switch(error.error_code) {
                case 'USER_PROFILE_EMAIL_INUSE':
                    this.error_message = 'Ese email ya se encuentra en uso.';
                    this.errors.email = true;
                    break;
                default:
                    break;
            }
        }
    },
    mounted() {
        this.$store.dispatch('user/getProfile')
            .then(data => {
                this.new_data = data;
            });
        // UserService.getProfile()
        //     .then(data => {
        //         this.$store.commit('user/getProfile', data.data);
        //         this.new_data = data.data;
        //     });
    }
}
</script>
