<template>
  <div role="main" class="main shop pt-4">
    <div class="container">
      <div class="row">
        <div class="col">
          <ul class="breadcrumb bd-block text-4 mb-4">
            <li>
              <router-link
                to="/"
                class="
                  text-color-default
                  text-color-hover-primary
                  text-decoration-none
                "
              >
                <i class="fas fa-home"></i>
              </router-link>
            </li>
            <li>
              <a
                href="#"
                class="
                  text-color-default
                  text-color-hover-primary
                  text-decoration-none
                "
                >Pool</a
              >
            </li>
            <li>{{ categoryName }}</li>
            <li>{{ subcategoryName }}</li>
          </ul>
        </div>
      </div>
      <AppPoolStepper
        :pool="pool"
        :shippingstep="bidShippingStep"
        :detailstep="bidDetailStep"
        :bidsuccessful="bidSuccessful"
        v-if="userType == 2 && poolState == 1"
      />
      <div class="row">
        <div class="col-md-4 mb-5 mb-md-0 col-10">
          <AppPoolSlider v-if="!bidShippingStep && !bidDetailStep" />
          <AppPoolMap v-else />
        </div>
        <div class="col-md-1 col-2">
          <div class="progress progress-bar-vertical">
            <div
              class="
                progress-bar
                progress-bar-danger
                progress-bar-striped
                progress-bar-animated
              "
              role="progressbar"
              aria-valuenow="60"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="{ height: poolProgress + '%' }"
            >
              <span class="progress-bar-tooltip" style="opacity: 1">
                {{ poolVolume }} {{ $helpers.formatUnit(unit) }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-12">
          <div class="summary entry-summary position-relative">
            <div class="position-absolute top-0 right-0 diasrestantes">
              <app-pool-countdown
                :end="(poolState == 0) ? pool.finish_date : pool.date_finish"
                :isfinished="finished"
              />
            </div>
            <h1 class="mb-0 font-weight-bold text-7 text-uppercase">
              {{ pool.product.nombre_comercial }}
            </h1>
            <p>{{ pool.location }}</p>
            <hr />
            <!-- <p class="text-3-5 mb-3 cazul">Estado actual</p> -->
            <!-- Data panel according to pool state -->
            <app-pool-data-panel-initial v-if="poolState == 0" />
            <app-pool-data-panel-shipping v-else-if="poolState == 1 && bidShippingStep" />
            <app-pool-data-panel-completed v-else-if="poolState == 1" />
            <app-pool-data-panel-finished v-else-if="poolState == 2" />

            <hr />

            <app-pool-reservation
              v-if="!userType || userType == 1"
              @changeprogress="updatePoolProgress"
              @changevolume="updatePoolVolume"
              @resetprogress="resetPoolProgress"
            />

            <app-pool-bid
              v-if="userType == 2 && !bidShippingStep && !bidDetailStep"
              @changeprice="updatePrice"
              @nextstep="setBidShippingStep"
            />
            <app-pool-bid-shipping
              v-else-if="userType == 2 && bidShippingStep"
              @nextstep="setBidDetailStep"
            />
            <app-pool-bid-detail
              v-else-if="userType == 2 && bidDetailStep"
              @bidsuccessful="bidSuccessful=true"
            />

            <hr />
            <div class="d-flex align-items-center">
              <ul
                class="
                  social-icons
                  social-icons-medium
                  social-icons-clean-with-border
                  social-icons-clean-with-border-border-grey
                  social-icons-clean-with-border-icon-dark
                  mr-3
                  mb-0
                "
              >
                <!-- Facebook -->
                <li class="social-icons-facebook">
                  <a
                    href="http://www.facebook.com/sharer.php?u=http://epooler.com/"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Compartir en Facebook"
                  >
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <!-- Twitter -->
                <li class="social-icons-twitter">
                  <a
                    href="https://twitter.com/share?url=http://epooler.com/&amp;text=Tractor%20Chery%20Cabina&amp;hashtags=epooleragro"
                    target="_blank"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Compartir en Twitter"
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <!-- Email -->
                <li class="social-icons-email">
                  <a
                    href="mailto:?Subject=Tractor Chery cabina&amp;Body=Hola%20encontre%20este%20pool%20para%20vos!%20 http://epooler.com/"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Compatir en Email"
                  >
                    <i class="far fa-envelope"></i>
                  </a>
                </li>
              </ul>
              <a
                class="
                  d-flex
                  align-items-center
                  text-decoration-none text-color-dark text-color-hover-primary
                  font-weight-semibold
                  text-2
                  cursor-pointer
                "
                v-if="loggedIn"
                v-on:click="followPool"
              >
                <i
                  class="far fa-heart mr-1"
                  v-bind:class="{ 'followed-heart': isFollowed }"
                ></i>
                <span v-if="isFollowed">QUITAR DE FAVORITOS</span>
                <span v-else>GUARDAR EN FAVORITOS</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col">
          <div
            id="description"
            class="
              tabs
              tabs-simple
              tabs-simple-full-width-line
              tabs-product
              tabs-dark
              mb-2
            "
          >
            <ul class="nav nav-tabs justify-content-start">
              <li
                class="nav-item"
                v-bind:class="{ active: activeTab == 'description' }"
              >
                <a
                  class="nav-link active font-weight-bold text-3 py-2 px-3"
                  href="javacript:void(0)"
                  v-on:click="activeTab = 'description'"
                  >Descripción</a
                >
              </li>
              <li
                class="nav-item"
                v-bind:class="{ active: activeTab == 'presentation' }"
              >
                <a
                  class="nav-link font-weight-bold text-3 py-2 px-3"
                  href="javacript:void(0)"
                  v-on:click="activeTab = 'presentation'"
                  >Presentación</a
                >
              </li>
              <li
                class="nav-item"
                v-bind:class="{ active: activeTab == 'data_sheet' }"
                v-if="areThereExistingDocuments()"
              >
                <a class="nav-link nav-link-reviews font-weight-bold text-3 py-2 px-3"
                  href="javacript:void(0)"
                  v-on:click="activeTab = 'data_sheet'"
                  >Links y descargas
                </a>
              </li>
            </ul>
            <div class="tab-content p-0">
              <div
                class="tab-pane px-0 py-3"
                id="productoDescripcion"
                v-bind:class="{ active: activeTab == 'description' }"
              >
                <p>{{ pool.product.descripcion }}</p>
              </div>
              <div
                class="tab-pane px-0 py-3"
                id="productoPresentacion"
                v-bind:class="{ active: activeTab == 'presentation' }"
              >
                <p>{{ pool.product.presentacion }}</p>
              </div>
              <div
                class="tab-pane px-0 py-3 text-center"
                id="productoFicha"
                v-bind:class="{ active: activeTab == 'data_sheet' }"
                v-if="areThereExistingDocuments()"
              >
                <a target="_blank" v-if="pool.product.website !== ''" class="btn btn-azul btn-modern text-uppercase mr-2" :href="pool.product.website">
                  Sitio Web
                </a>
                <a target="_blank" v-if="pool.product.ficha_tecnica !== ''" class="btn btn-azul btn-modern text-uppercase mr-2" :href="$helpers.apiAsset(pool.product.ficha_tecnica)">
                  Descargar ficha tecnica
                </a>
                <a target="_blank" v-if="pool.product.security_sheet !== ''" class="btn btn-azul btn-modern text-uppercase mr-2" :href="$helpers.apiAsset(pool.product.security_sheet)">
                  Descargar hoja de seguridad
                </a>
                <a target="_blank" v-if="pool.product.label_sheet !== ''" class="btn btn-azul btn-modern text-uppercase" :href="$helpers.apiAsset(pool.product.label_sheet)">
                  Descargar etiqueta
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row shop border-0 my-0 bgpoolhome pt-4" v-if="similarPools.length > 0">
      <div class="container">
        <div class="col">
          <h4 class="cazul text-7 mb-4">
            <i class="far fa-clock mr-2"></i> Pools Similares
          </h4>
          <carousel
            class="
              owl-carousel owl-theme
              show-nav-hover
              owl-loaded owl-drag owl-carousel-init
            "
            :autoplay="false"
            :navText="['<', '>']"
            :margin="20"
            :loop="false"
            :nav="true"
            :dots="false"
            :responsive="{
              '0': { items: 2 },
              '479': { items: 2 },
              '768': { items: 2 },
              '979': { items: 4 },
              '1199': { items: 4 },
            }"
          >
            <app-pool-in-process
              v-for="(pool, index) in similarPools"
              :key="index"
              :poolname="pool.name"
            />
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AppPoolInProcess from "@/components/layout/common/AppPoolInProcess";
import AppPoolSlider from "@/components/layout/pool/AppPoolSlider";
import AppPoolCountdown from "@/components/layout/pool/AppPoolCountdown";
import AppPoolDataPanelInitial from "@/components/layout/pool/AppPoolDataPanelInitial";
import AppPoolDataPanelCompleted from "@/components/layout/pool/AppPoolDataPanelCompleted";
import AppPoolDataPanelShipping from "@/components/layout/pool/AppPoolDataPanelShipping";
import AppPoolDataPanelFinished from "@/components/layout/pool/AppPoolDataPanelFinished";
import AppPoolReservation from "@/components/layout/pool/AppPoolReservation";
import AppPoolBid from "@/components/layout/pool/AppPoolBid";
import AppPoolBidShipping from "@/components/layout/pool/AppPoolBidShipping";
import AppPoolBidDetail from "@/components/layout/pool/AppPoolBidDetail";
import AppPoolMap from "@/components/layout/pool/AppPoolMap";
import AppPoolStepper from "@/components/layout/pool/AppPoolStepper";

import carousel from "vue-owl-carousel";

import store from "../store";
import { mapState } from "vuex";

export default {
  name: "Pool",
  components: {
    AppPoolInProcess,
    AppPoolSlider,
    carousel,
    AppPoolCountdown,
    AppPoolDataPanelInitial,
    AppPoolDataPanelCompleted,
    AppPoolDataPanelShipping,
    AppPoolDataPanelFinished,
    AppPoolReservation,
    AppPoolBid,
    AppPoolBidShipping,
    AppPoolBidDetail,
    AppPoolMap,
    AppPoolStepper
  },
  data() {
    return {
      poolProgress: 0,
      poolVolume: 0,
      unit: '',
      finished: false,
      activeTab: "description",
      similarPools: [],
      new_reservation: {
        amount: 0,
      },
      bidShippingStep: false,
      bidDetailStep: false,
      bidSuccessful: false
    };
  },
  computed: mapState({
    pool: (state) => state.pool.data,
    categoryName: (state) => state.pool.data.product.category.name,
    subcategoryName: (state) => state.pool.data.product.subcategory.name,
    loggedIn: (state) => state.auth.status.loggedIn,
    userType: (state) => {
      return (state.auth.user) ? state.auth.user.user_data.type : 0
    },
    volume: (state) => state.pool.data.volume,
    buyers: (state) => state.pool.data.buyers,
    criticMass: (state) => state.pool.data.product.masa_critica_1,
    reservations: (state) => state.pool.reservations,
    poolState: (state) => state.pool.data.state,
    isFollowed: (state) => state.pool.user_follows,
    originalPoolProgress: (state) => {
      return (state.pool.data.volume / state.pool.data.product.masa_critica_1) * 100;
    }
  }),
  methods: {
    getMaxAmount: () => {
      return this.criticMass - this.volume;
    },
    setPoolProgress() {
      let progress = this.originalPoolProgress;
      if (progress > 100) {
        progress = 100;
      }
      this.poolProgress = progress;
      this.poolVolume = this.pool.volume;
    },
    updatePoolProgress(value) {
      this.poolProgress = this.originalPoolProgress + value;
    },
    resetPoolProgress() {
      this.poolProgress = this.originalPoolProgress;
    },
    updatePoolVolume(value) {
      this.poolVolume = this.pool.volume + value;
    },
    updatePrice(value) {
      return value;
    },
    updateShippingPrice(value) {
      return value;
    },
    setBidShippingStep() {
      this.$store.dispatch('pool/getPoolReservations', this.pool.uuid)
        .then(() => {
            this.bidShippingStep = true;
        });
    },
    setBidDetailStep() {
      this.bidShippingStep = false;
      this.bidDetailStep = true;
    },
    finishPool() {
      this.finished = true;
    },
    followPool() {
      this.$store.dispatch('pool/followPool', this.pool.uuid);
    },
    updatePoolData(data) {
        this.$store.commit('pool/getData', data);
        this.setPoolProgress();
    },
    areThereExistingDocuments() {
      return (
        this.pool.product.ficha_tecnica !== '' ||
        this.pool.product.security_sheet !== '' ||
        this.pool.product.label_sheet !== ''
      );
    }
  },
  beforeRouteEnter(to, from, next) {
    const pool_uuid = to.params.uuid;

    store.dispatch('pool/getData', pool_uuid)
        .catch((error) => {
            if(error.response.status == 404) {
                location.href = '/';
            }
        })
        .then(() => {
            next();
        });
  },
  beforeRouteUpdate (to, from, next) {
    const pool_uuid = to.params.uuid;

    store.dispatch('pool/getData', pool_uuid)
    .catch((error) => {
        if(error.response.status == 404) {
          location.href = '/';
        }
    })
    .then(() => {
        next();
    });
  },
  mounted: function () {
    // Initial data
    this.setPoolProgress();
    this.unit = this.pool.product.unidad_medida;

    if (this.poolState == 2) {
      this.finished = true;
    }

    this.$connect();
    this.$socket.onopen = () => {
        this.$socket.send(JSON.stringify({
            action: 'subscribe',
            pool_uuid: this.pool.uuid
        }));
    };
    
    this.$options.sockets.onmessage = (message) => {
        let json_data = null;
        try {
            json_data = JSON.parse(JSON.parse(message.data));
        } catch (e) {
            // console.log('Not JSON: ' + message.data);
        }
        if (json_data) {
            this.updatePoolData(json_data);
        }

    }
  },
};
</script>

<style src='ion-rangeslider/css/ion.rangeSlider.min.css'></style>
<style type="text/css">
.followed-heart {
  font-weight: bold;
  color: red;
}
</style>
