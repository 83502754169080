import Vue from 'vue'
import App from './App.vue'
import VueHead from 'vue-head'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Constants from "@/constants/constants";
import * as VueGoogleMaps from 'vue2-google-maps'

import helpers from './helpers/helpers';
import VueNativeSock from 'vue-native-websocket'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/css/fontawesome-free/css/all.min.css'
import '@/assets/css/bs-stepper.min.css'

import VTooltip from 'v-tooltip'

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueHead)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Constants)
Vue.use(VTooltip)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLEMAPS_API_KEY,
    libraries: 'places,geocoder', // This is required if you use the Autocomplete plugin
  },
  installComponents: true
})

// Helpers declaration
Vue.use({
  install () {
    Vue.helpers = helpers
    Vue.prototype.$helpers = helpers
  }
})

Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKET_URL, {
  // format: 'json'
  connectManually: true
});
// Vue.use(VueNativeSock, process.env.VUE_APP_WEBSOCKET_URL);

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')
