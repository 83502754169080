import $ from 'jquery';

class Utils {
    toggleDropdown(e) {
        e.preventDefault();

        let $this = $(e.target);
        let $toggleParent = $this.parent();

        if (!$this.siblings('.header-nav-features-dropdown').hasClass('show')) {
            let $dropdown = $this.siblings('.header-nav-features-dropdown');

            $('.header-nav-features-dropdown.show').removeClass('show');

            $dropdown.addClass('show');
            $(document).off('click.header-nav-features-toggle').on('click.header-nav-features-toggle', function(e) {
                if (!$toggleParent.is(e.target) && $toggleParent.has(e.target).length === 0) {
                    $('.header-nav-features-dropdown.show').removeClass('show');
                }
            });

            if ($this.attr('data-focus')) {
                $('#' + $this.attr('data-focus')).focus();
            }
        } else {
            $this.siblings('.header-nav-features-dropdown').removeClass('show');
        }
    }
}

export default new Utils();