import { httpClient } from './http-client';

class ProductService {
    getAll() {
        return httpClient
          .get('public/products/list')
          .then(response => {
              return response.data;
          });
    }
}

export default new ProductService();