<template>
  <div id="app" class="body">
    <AppHeader></AppHeader>
    <router-view/>
    <AppFooter></AppFooter>
  </div>
</template>

<style>

  @import './assets/css/animate.compat.css';
  @import './assets/css/theme.css';
  @import './assets/css/theme-elements.css';
  @import './assets/css/theme-shop.css';
  
  @import './assets/css/skins/default.css';
  @import './assets/css/custom.css';

</style>

<script>
import AppHeader from './components/layout/common/AppHeader';
import AppFooter from './components/layout/common/AppFooter';

export default {
  name: 'App',
  components: {AppHeader, AppFooter},
  props: {
    msg: String
  },
  data: function () {
    return {
      title: 'Epooler Agro | Home'
    }
  },
  head: {
      title: function () {
        return {
          inner: this.title
        }
      },
      meta: [
        { name: 'author', content: 'Epooler Agro'},
        { name: 'keywords', content: 'Epooler Agro'},
        { name: 'description', content: 'Epooler Agro'},
        { name: 'viewport', content: 'width=device-width, initial-scale=1, minimum-scale=1.0, shrink-to-fit=no'},
        { name: 'charset', content: 'utf-8'},

      ],
      link: [
        { rel: 'stylesheet', type: 'text/css', href: 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800%7CShadows+Into+Light%7CPlayfair+Display:400&display=swap', id: 'googleFonts' }
      ] 
    }
}
</script>

