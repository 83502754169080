<template>
  <div>
    <div class="row">
      <div class="col-sm-3 col-6" v-tooltip.auto="'Incluye costo de distribución'">
        <div class="cajaestados es1">
          <b>{{ getPrice() }}</b>
          <br />tu oferta
          <i class="fas fa-dollar-sign"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es2">
          <b>{{ getPotentialDiscount() }}%</b>
          <br />
          <span v-if="this.pool.last_price">descuento</span>
          <span v-else>dto. potencial</span>
          <i class="fas fa-percent"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es3">
          <b>{{ pool.buyers }}</b
          ><br />compradores <i class="fas fa-user-friends"></i>
        </div>
      </div>
      <div class="col-sm-3 col-6">
        <div class="cajaestados es3">
          <b>{{ getTotalDistance() }} km</b>
          <br />kilómetros totales
          <i class="fas fa-map-marker-alt"></i>
        </div>
      </div>
    </div>
    <hr />
    <!-- SECOND ROW DATA -->
    <div class="row">
      <div class="col-4 text-center ptarget" title="">
        <h4 class="mt-2">
          <i class="fas fa-unlock-alt text-success"></i>
          Volumen alcanzado
        </h4>
      </div>
      <div class="col-4 text-center pmercado">
        <b>{{
            $helpers.formatNumber(pool.product.masa_critica_1 - pool.volume)
          }}
          {{ unit }}</b>
        <br />Volumen restante
      </div>
      <div class="col-4 text-center pepooler">
        <b>{{ $helpers.formatNumber(pool.product.masa_critica_1) }}
          {{ unit }}</b>
        <br />Volumen a alcanzar
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "AppPoolDataPanelShipping",
    methods: {
        getTotalDistance() {
            return '-';
        },
        getPrice() {
            return this.$helpers.getPriceUnit(this.pool) + ' ' + this.$helpers.formatDecimal(this.newPrice + this.shippingPrice);
        },
        getPotentialDiscount() {
            let price = this.newPrice + this.shippingPrice;
            let disc = (1 - price / this.pool.product.precio_mercado) * 100;
            return Math.ceil(disc);
        }
    },
    computed: mapState({
        pool: (state) => state.pool.data,
        unit: (state) => state.pool.data.product.unidad_medida,
        newPrice: (state) => state.pool.new_bid.price,
        shippingPrice: (state) => state.pool.new_bid.shipping_price
    })
};
</script>