<template>
    <div>
        <div class="card card-body bg-color-light mb-4">
            <h4>
                <i class="far fa-check-circle"> </i>
                Pooles activos
            </h4>
            <table class="table">
                <tbody>
                    <tr v-for="(pool, index) in active_pools" :key="index">
                        <td class="tdimagenc">
                            <span class="img-thumbnail d-block">
                            <img class="img-fluid maxw-64" :src="$helpers.apiAsset(pool.product.imagen_destacada)" alt="Project Image" />
                            </span>
                        </td>
                        <td class="tdnombre">
                            {{ pool.product.nombre_comercial }}
                        </td>
                        <td class="tdprecio">${{ getPotencialPrice(pool) }} <span class="cgris"> Precio por {{ formatUnit(pool.product.unidad_medida) }}</span></td>
                        <td class="tdcomprdores">{{ pool.buyers }} <span class="cgris"> Compradores</span></td>
                        <td class="tddias">
                            <app-pool-countdown :end="pool.date_finish !== null ? pool.date_finish : pool.finish_date " :isfinished="finished" />
                            <!-- <span class="cgris"> Días restantes</span> -->
                        </td>
                        <td class="tdaccion">
                            <router-link :to="{ path: '/pool/' + pool.uuid }">
                                <i class="fas fa-search"></i>
                            </router-link>
                            <!-- <i class="fas fa-trash-alt"> </i> -->
                        </td>
                    </tr>
                    <tr v-if="active_pools.length == 0">
                        <td>
                            <strong>No participas en ningún pool activo</strong>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="6"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="card card-body bg-color-light mb-4">
            <h4>
                <i class="far fa-times-circle"> </i>
                Pooles cerrados
            </h4>
            <table class="table">
                <tbody>
                    <tr v-for="(pool, index) in finished_pools" :key="index">
                        <td class="tdimagenc">
                            <span class="img-thumbnail d-block">
                            <img class="img-fluid maxw-64" :src="$helpers.apiAsset(pool.product.imagen_destacada)" alt="Project Image" />
                            </span>
                        </td>
                        <td class="tdnombre">
                            {{ pool.product.nombre_comercial }}
                        </td>
                        <td class="tdprecio">{{ $helpers.getPriceUnit(pool) }} {{ getPotencialPrice(pool) }} <span class="cgris"> Precio por {{ formatUnit(pool.product.unidad_medida) }}</span></td>
                        <td class="tdcomprdores">{{ pool.buyers }} <span class="cgris"> Compradores</span></td>
                        <!-- <td class="tddias">
                            <app-pool-countdown :end="pool.fecha_fin" :isfinished="true" />
                        </td> -->
                        <td class="tdaccion">
                            <router-link :to="{ path: '/pool/' + pool.uuid }">
                                <i class="fas fa-search"></i>
                            </router-link>
                            <!-- <i class="fas fa-trash-alt"> </i> -->
                        </td>
                    </tr>
                    <tr v-if="finished_pools.length == 0">
                        <td>
                            <strong>No hay ningún pool finalizado.</strong>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="6"></td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>
<script>
    import AppPoolCountdown from '@/components/layout/pool/AppPoolCountdown';
    import UserService from '@/services/user-service';
    import { formatUnit } from "@/services/helpers.js"

    const POOL_STATE_FINISHED = 2;

    export default {
        name: 'AppMyAccountPools',
        components: { AppPoolCountdown },
        data() {
            return {
                finished_pools: [],
                active_pools: []
            }
        },
        methods: {
            formatUnit,
            getPotencialPrice(pool_data) {
                return this.$helpers.formatDecimal(pool_data.product.precio_mercado * ((100 - pool_data.product.descuento_1) / 100));
            },
            filterPools(data) {
                for(let pool of data) {
                    if(pool.state == POOL_STATE_FINISHED) {
                        this.finished_pools.push(pool);
                    }else{
                        this.active_pools.push(pool);
                    }
                }
            },
        },
        mounted() {
            UserService.getUserPools()
            .then(data => {
                this.filterPools(data.data.pools);
            });
        }
    }
</script>