export function formatUnit(unit) {
	switch(unit) {
		case 'kilos':
			return 'kg';
		case 'litros':
			return 'lts';
		case 'unidades':
			return 'uni';
	}
}

export function timeLeftString(to) {
	// format must be YYYY-MM-DD HH:II:SS
	let to_date = new Date(to);
	let today = new Date();
	const diffTime = to_date - today;

	const diffDays = diffTime / (1000 * 60 * 60 * 24);
	if(diffDays < 1) {
		const diffHours = diffTime / (1000 * 60 * 60);
		if(diffHours < 1) {
			const diffMinutes = diffTime / (1000 * 60);
			return Math.ceil(diffMinutes) + ' minutos restantes';
		}
		return Math.ceil(diffHours) + ' horas restantes';
	}
	return Math.ceil(diffDays) + ' días restantes';
}

export function formatNumber(n) {
	return new Intl.NumberFormat('de-DE').format(n);
}

export function formatDecimal(n) {
	return new Intl.NumberFormat('de-DE').format(n.toFixed(2));
}
