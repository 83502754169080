<template>
  <div class="thumb-gallery-wrapper">
    <vueper-slides
      ref="vueperslides1"
      :touchable="false"
      fade
      :autoplay="false"
      :bullets="false"
      @slide="
        $refs.vueperslides2.goToSlide($event.currentSlide.index, {
          emit: false,
        })
      "
      fixed-height="400px"
    >
      <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image">
      </vueper-slide>
    </vueper-slides>
    <vueper-slides
      v-if="slides.length > 1"
      class="no-shadow thumbnails"
      ref="vueperslides2"
      @slide="
        $refs.vueperslides1.goToSlide($event.currentSlide.index, {
          emit: false,
        })
      "
      :visible-slides="slides.length"
      fixed-height="75px"
      :bullets="false"
      :touchable="false"
      :gap="2.5"
      :arrows="false"
    >
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :image="slide.image"
        @click.native="$refs.vueperslides2.goToSlide(i)"
      >
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<style type="text/css">
.thumbnails {
  margin: auto;
  max-width: 350px;
  margin-top: 10px;
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 1px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: #000;
}
</style>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapState } from "vuex";

export default {
  name: "AppPoolSlider",
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      slides: [],
    };
  },
  computed: mapState({
    mainImage: (state) => state.pool.data.product.imagen_destacada,
  }),
  mounted() {
    this.slides = [{ image: this.$helpers.apiAsset(this.mainImage) }];
  },
};
</script>