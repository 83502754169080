<template>
  <div :class="colwidth">
    <div class="product mb-0 bg-color-white pt-3">
        <div class="product-thumb-info mb-3">
            <div class="product-thumb-info-badges-wrapper esqahorro">
                <span class="badge badge-ecommerce badget-ahorro">POTENCIAL AHORRO {{ discount() }}%</span>
            </div>

            <a v-on:click="goToPool">
                <div class="product-thumb-info-image">
                    <img alt="" class="img-fluid" :src="$helpers.apiAsset(pool.product.imagen_destacada)" />
                </div>
            </a>

            <div class="bg-barra-cardpool"></div>
            <div id="cardpool" class="progress progress-bar-vertical">
            <div class="progress-bar progress-bar-danger progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" :style="{height: poolProgress + '%' }">
                <span class="progress-bar-tooltip" style="opacity: 1;">{{ Math.ceil(pool.volume) }} {{ $helpers.formatUnit(pool.product.unidad_medida) }}</span>
            </div>
          </div>
            
        </div>
        
        <div class="d-flex justify-content-between pl-3 pr-3 pb-4 pt-1">
            <div>
                <div class="pool-meta">
                    <span class="cnaranja mr-3" data-toggle="tooltip" data-placement="top" data-html="true" data-original-title="Al pool<br>le quedan 10hs">
                        <!-- <i class="far fa-clock cnaranja"></i> Quedan 10hs -->
                        <app-pool-countdown :end="pool.finish_date" :isfinished="finished" />
                    </span>
                    <span class="cazul mr-3" v-b-tooltip.hover :title="`El pool cuenta con ${pool.buyers} compradores`">
                        <i class="fas fa-user-friends cazul"></i> {{ pool.buyers }} 
                    </span>
                    <span class="cazul mr-3" v-b-tooltip.hover.top :title="`El precio potencial es de ${ priceUnit() } ${$helpers.getPotentialPrice(pool)}`">
                        <i class="fas fa-dollar-sign cazul"></i> {{ price() }} {{ priceUnit() }}
                    </span>
                </div>
                <h3 class="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-1">
                    <a v-on:click="goToPool" class="text-color-dark text-color-hover-cyan">{{ pool.product.nombre_comercial }}</a>
                </h3>
                <h3 class="text-2 font-alternative text-transform-none line-height-3 mb-0">
                    <a v-on:click="goToPool" class="text-color-default text-color-hover-cyan">{{pool.location}}</a>
                </h3>
                <a v-on:click="goToPool" class="btn btn-azul btn-modern m-0 mt-2">VER POOL</a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import AppPoolCountdown from '@/components/layout/pool/AppPoolCountdown';

export default {
  name: 'AppPoolInProcess',
  props: ['pool', 'colwidth'],
  components: { AppPoolCountdown },
  data() {
    return {
        poolProgress: 0,
        finished: false
    }
  },
  methods: {
    discount() {
        return (this.pool.last_price) ? this.calculateDiscount()
                                        : Math.ceil(this.pool.product.descuento_1);
    },
    price() {
        return (this.pool.last_price) 
                ? this.$helpers.formatDecimal(this.pool.last_price.price + this.pool.last_price.shipping_price) 
                : this.$helpers.getPotentialPrice(this.pool);
    },
    priceUnit() {
        return this.$helpers.getPriceUnit(this.pool);
    },
    calculateDiscount() {
        const disc = (1 - this.pool.last_price.price / this.pool.product.precio_mercado) * 100;
        return Math.ceil(disc);
    },
    setPoolProgress() {
        let progress = ((this.pool.volume / this.pool.product.masa_critica_1) * 100);
        if(progress > 100) {
            progress = 100;
        }
        this.poolProgress = progress;
    },
    goToPool() {
        this.$router.push({ path: `/pool/${this.pool.uuid}` });
    }
  },
  mounted() {
    this.setPoolProgress();
  }
}
</script>