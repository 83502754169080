<template>
      <div class="container">
         <div class="row">
            <div class="col">
               <div class="row process">
                  <div class="process-step col-lg-4 mb-5 mb-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200" style="animation-delay: 200ms;">
                     <div class="process-step-circle">
                        <strong class="process-step-circle-content"><i class="icons fas fa-headphones-alt text-5 text-primary verdeoscuro"></i></strong>
                     </div>
                     <div class="process-step-content px-4">
                        <h4 class="mb-1 text-5 font-weight-bold mb-2">Soporte</h4>
                        <p class="mb-0">¿Necesitas ayuda?<br><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rhoncus nulla dui, in dapibus magna aliquam et.</p>
                     </div>
                  </div>
                  <div class="process-step col-lg-4 mb-5 mb-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" style="animation-delay: 400ms;">
                     <div class="process-step-circle">
                        <strong class="process-step-circle-content"><i class="icons fas fa-credit-card text-5 text-primary verdeoscuro"></i></strong>
                     </div>
                     <div class="process-step-content px-4">
                        <h4 class="mb-1 text-5 font-weight-bold mb-2">Pago Seguro</h4>
                        <p class="mb-0">Su dinero esta asegurado<br><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rhoncus nulla dui, in dapibus magna aliquam et.</p>
                     </div>
                  </div>
                  <div class="process-step col-lg-4 mb-5 mb-lg-4 appear-animation animated fadeInUpShorter appear-animation-visible" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600" style="animation-delay: 600ms;">
                     <div class="process-step-circle">
                        <strong class="process-step-circle-content"><i class="icons fas fa-truck text-5 text-primary verdeoscuro"></i></strong>
                     </div>
                     <div class="process-step-content px-4">
                        <h4 class="mb-1 text-5 font-weight-bold mb-2">Envíos</h4>
                        <p class="mb-0">Fácil y rápido<br><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur rhoncus nulla dui, in dapibus magna aliquam et.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
</template>

<script>
export default {
  name: 'AppHomeServices',
  props: {
    msg: String
  }
}
</script>