import PoolService from '@/services/pool-service';

export const pool = {
    namespaced: true,
    state: {
        data: {},
        reservations: {
            total_amount: 0,
            count: 0,
            list: [],
            shipping_list: []
        },
        bids: {
            last_price: null,
            count: 0,
            list: []
        },
        new_bid: {
            price: null,
            shipping_price: null
        },
        lists: {
            in_process: [],
            completed: []
        },
        user_follows: false,
    },
    actions: {
        getData({ commit, rootGetters }, pool_uuid) {
            const logged_in = rootGetters["auth/isLogged"];
            const user_type = rootGetters["auth/userType"];

            return PoolService.getData(pool_uuid, logged_in)
                .then((response) => {
                    if (logged_in) {
                        switch (user_type) {
                            case 1:
                                commit("getReservations", response.data.reservations);
                                delete response.data.reservations;
                                break;
                            case 2:
                                commit("getBids", response.data.bids);
                                delete response.data.bids;
                                break;
                            default:
                                break;
                        }
                    }
                    commit("getData", response.data);
                    commit("userFollows", response.data.user_follows);
                    return Promise.resolve();
                });
        },
        makeReservation({ commit }, { pool_uuid, amount }) {
            PoolService.makeReservation(pool_uuid, amount)
                .then(data => {
                    commit('addReservation', {
                        uuid: data.data.uuid,
                        amount: amount,
                        pool_uuid: pool_uuid,
                        date: new Date()
                    });
                    commit('updatePoolStatus');
                    return Promise.resolve(data.data);
                });
        },
        makeBid({ commit }, { pool_uuid, price, shipping_price}) {
            return PoolService.makeBid(pool_uuid, price, shipping_price)
                .then(data => {
                        commit('addBid', {
                            uuid: data.data.uuid,
                            amount: price,
                            shipping_amount: shipping_price,
                            pool_uuid: pool_uuid,
                            date: new Date()
                        });
                        return Promise.resolve(data.data);
                    },
                    error => {
                        return Promise.reject(error);
                    }
                );
                
        },
        subscribe({ commit }, pool_uuid) {
            PoolService.followPool(pool_uuid).then(() => {
                commit('userFollows');
            });
        },
        getList({ commit, rootGetters }, type) {
            const logged_in = rootGetters["auth/isLogged"];
            return PoolService.list(type, logged_in).then((response) => {
                if(response) {
                    commit('addPoolList', {
                        type,
                        pool_list: response.data.data
                    });
                }
                return Promise.resolve(); 
            });
        },
        getPoolReservations({ commit }, pool_uuid) {
            return PoolService.getReservations(pool_uuid)
                .then((response) => {
                    if(response) {
                        commit('getPoolReservations', response.data.reservations);
                    }
                    return Promise.resolve(); 
                }
            );
        },
        followPool({ commit }, pool_uuid) {
            return PoolService.followPool(pool_uuid)
                .then(() => {
                    commit('userFollows');
                    return Promise.resolve(); 
                });
        }
    },
    mutations: {
        addPoolList(state, {type, pool_list}) {
            state.lists[type] = pool_list;
        },
        getData(state, data) {
            state.data = data;
        },
        addBuyer(state) {
            state.data.buyers += 1;
        },
        addReservation(state, reservation_data) {
            state.reservations.total_amount += reservation_data.amount;
            state.reservations.count += 1;
            state.reservations.list.push(reservation_data);
            state.data.volume += reservation_data.amount;
        },
        updatePoolStatus(state) {
            if(state.data.volume >= state.data.product.masa_critica_1) {
                state.data.state = 1;
            }
        },
        addBid(state, bid_data) {
            state.bids.last_price = bid_data.amount;
            state.bids.count += 1;
            state.bids.list.push(bid_data);
        },
        getReservations(state, reservations_data) {
            state.reservations = reservations_data;
        },
        getBids(state, bids_data) {
            state.bids = bids_data;
        },
        setNewPrice(state, new_bid) {
            state.new_bid.price = new_bid;
        },
        setNewShippingPrice(state, new_bid) {
            state.new_bid.shipping_price = new_bid;
        },
        userFollows(state, follows = null) {
            if (follows === null) {
                state.user_follows = !state.user_follows;
            } else {
                state.user_follows = follows;
            }
        },
        getPoolReservations(state, reservations) {
            state.reservations.shipping_list = reservations;
        }
    },
    getters: {
        getData: state => {
            return state.data
        },
        getLists: (state) => {
            return state.lists;
        }
    }
};
