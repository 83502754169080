<template>
    <footer id="footer" class="bg-color-primary border-top-0">
        <div class="container py-4">
            <div class="row py-5">
                <div class="col-lg-6">
                    <h5 class="text-4 text-color-light mb-3">Suscribite al newsletter</h5>
                    <p class="mb-2 text-color-light">Enterate de todas las novedades relacionadas al agro:</p>
                    <div class="alert alert-success d-none" id="newsletterSuccess">
                        <strong>Enviado!</strong>
                    </div>
                    <div class="alert alert-danger d-none" id="newsletterError"></div>
                    <form id="newsletterForm" class="form-style-5 opacity-10" action="php/newsletter-subscribe.php" method="POST">
                        <div class="form-row">
                            <div class="form-group col">
                                <input type="email" class="form-control m-b-3" placeholder="Ingresar e-mail" required="" />
                                <input type="submit" class="btn btn-primary shadow-none" value="Enviar" />
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-lg-4 mb-4 mb-md-0">
                    <h5 class="text-4 mb-3 text-color-light">CONTACTO</h5>
                    <ul class="list list-icons list-icons-lg">
                        <li class="mb-1">
                            <i class="far fa-dot-circle text-color-light"></i>
                            <p class="m-0 text-color-light">1234 Calle, Ciudad, Provincia</p>
                        </li>
                        <li class="mb-1">
                            <i class="fab fa-whatsapp text-color-light"></i>
                            <p class="m-0"><a class="text-color-light" href="tel:8001234567">(+54) 123-4567</a></p>
                        </li>
                        <li class="mb-1">
                            <i class="far fa-envelope text-color-light"></i>
                            <p class="m-0"><a class="text-color-light" href="mailto:mail@example.com">mail@epooleragro.com</a></p>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-2">
                    <h5 class="text-4 mb-3 text-color-light">SEGUINOS</h5>
                    <ul class="header-social-icons social-icons">
                        <li class="social-icons-facebook">
                            <a href="http://www.facebook.com/" target="_blank" title="Facebook"><i class="fab fa-facebook-f text-2"></i></a>
                        </li>
                        <li class="social-icons-twitter">
                            <a href="http://www.twitter.com/" target="_blank" title="Twitter"><i class="fab fa-twitter text-2"></i></a>
                        </li>
                        <li class="social-icons-linkedin">
                            <a href="http://www.linkedin.com/" target="_blank" title="Linkedin"><i class="fab fa-linkedin-in text-2"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-copyright bg-color-dark bg-color-scale-overlay bg-color-scale-overlay-1">
            <div class="bg-color-scale-overlay-wrapper">
                <div class="container py-2">
                    <div class="row py-4">
                        <div class="col-lg-1 d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0">
                            <a href="#" class="logo pr-0 pr-lg-3">
                                <img alt="Epooler Agro" src="@/assets/img/logo.svg" height="32" />
                            </a>
                        </div>
                        <div class="col-lg-7 d-flex align-items-center justify-content-center justify-content-lg-start mb-4 mb-lg-0">
                            <p class="text-color-light">Epooler Agro. © 2021. Todos los derechos reservados</p>
                        </div>
                        <div class="col-lg-4 d-flex align-items-center justify-content-center justify-content-lg-end">
                            <nav id="sub-menu">
                                <ul>
                                    <li class="border-0"><i class="fas fa-angle-right text-color-light"></i><a href="#" class="ml-1 text-decoration-none text-color-light"> FAQ's</a></li>
                                    <li class="border-0"><i class="fas fa-angle-right text-color-light"></i><a href="#" class="ml-1 text-decoration-none text-color-light"> Quienes Somos</a></li>
                                    <li class="border-0"><i class="fas fa-angle-right text-color-light"></i><a href="#" class="ml-1 text-decoration-none text-color-light"> Terminos y Condiciones</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  props: {
    msg: String
  }
}
</script>